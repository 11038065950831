import { useState } from "react";

import { useNavigate } from "react-router";

import { path } from "@/routes";
import { useClearCache } from "nid-common";
import { useLeaveApp } from "nid-common";
import { useUserStatus } from "nid-common";
import { getRegisteredRelyingParties } from "nid-common/api/account";
import { postWithdraw } from "nid-common/api/account";
import useSWR from "swr";

export const useWithdrawFeature = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { loginId } = useUserStatus();
  const apiReturn = useSWR(
    "/account/service-in-use",
    getRegisteredRelyingParties,
  );
  const navigate = useNavigate();
  const { clearAllSwrCache } = useClearCache();
  const { redirect } = useLeaveApp();

  if (apiReturn.isLoading) {
    return { status: "loading" } as const;
  }

  if (apiReturn.error) {
    const error = apiReturn.error;
    return { status: "error", error } as const;
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await postWithdraw();
      clearAllSwrCache();
      redirect(response.data.delete_sso_cookies_url);
    } catch (_) {
      navigate(path.error.root);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    status: "ok",
    email: loginId,
    data: apiReturn.data?.data,
    handleSubmit,
    handleCheckboxChange,
    isSubmitting,
    isChecked,
  };
};
