import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { ServiceContractCancelCompleteFeature } from "@/features/Services/Contract/Cancel/ServiceContractCancelCompleteFeature";
import { Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

export const ServiceContractCancelCompletePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        title={t("services.service_contract_cancel_complete.title")}
        testId="article-service-contract-cancel-complete"
      >
        <Typography as="p" lineHeight={"165"} size={"16"}>
          {t("services.service_contract_cancel_complete.description")}
        </Typography>
      </ArticleTitle>
      <ServiceContractCancelCompleteFeature />
    </>
  );
};

export default ServiceContractCancelCompletePage;
