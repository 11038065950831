import { Box, InfoCircleFillIcon, Link } from "nikkei-ui";
import { useTranslation } from "react-i18next";

const helpUrl = import.meta.env.VITE_URL_NIKKEI_ID_HELP_PASSKEY;

export const HelperText = () => {
  const { t } = useTranslation();
  return (
    <Box className="nid-row mt-4">
      <InfoCircleFillIcon width={16} height={16} role="img" />
      <Link to={helpUrl} size="14" newTab>
        {t("security.passkey.helper_text")}
      </Link>
    </Box>
  );
};
