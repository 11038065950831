import { I18nextProvider } from "react-i18next";

import { OpenFeatureProvider } from "@openfeature/react-sdk";
import { OpenFeature } from "@openfeature/web-sdk";
import { NidFeatureFlagProvider } from "nid-common";
import { HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";
import i18n from "./i18n/i18n";

OpenFeature.setProvider(new NidFeatureFlagProvider());

const App = ({ children }: { children: React.ReactNode }) => (
  <SWRConfig
    value={{
      provider: () => new Map(),
      errorRetryCount: 3,
      errorRetryInterval: 100,
    }}
  >
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <OpenFeatureProvider>{children}</OpenFeatureProvider>
      </I18nextProvider>
    </HelmetProvider>
  </SWRConfig>
);

export default App;
