import { Box, Button, Modal, Typography, useModal } from "nikkei-ui";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type UndoCancelContractProps = {
  onStart: () => Promise<void>;
  onFinished: () => Promise<void>;
  showCancelButton: boolean;
};

const UndoCancelContract: React.FC<UndoCancelContractProps> = (props) => {
  const { t } = useTranslation();

  const [completed, setCompleted] = useState(false);
  const [undoButtonDisabled, setUndoButtonDisabled] = useState(false);
  const { registerModal, openModal, closeModal } = useModal({
    onClose: async () => {
      setUndoButtonDisabled(false);
    },
  });

  const onUndoCancelConfirm = async () => {
    setCompleted(false);
    setUndoButtonDisabled(true);
    await props.onStart();
    setCompleted(true);
    await props.onFinished();
  };

  return (
    <>
      {props.showCancelButton && (
        <Button
          onClick={openModal}
          size="full"
          data-testid={"contract-undo-cancel-button"}
        >
          {t("services.service_contract.undo_cancel.text")}
        </Button>
      )}

      <Modal
        title={
          completed
            ? t("services.service_contract.undo_cancel_complete.modal.title")
            : t("services.service_contract.undo_cancel.modal.title")
        }
        {...registerModal}
      >
        {completed ? (
          <>
            <Typography lineHeight={"165"}>
              {t(
                "services.service_contract.undo_cancel_complete.modal.description",
              )}
            </Typography>

            <Button
              size={"full"}
              onClick={closeModal}
              className={"mt-6"}
              type={"button"}
              data-testid={"modal-close-button"}
            >
              {t("services.service_contract.undo_cancel_complete.modal.close")}
            </Button>
          </>
        ) : (
          <>
            <Typography lineHeight={"165"}>
              {t("services.service_contract.undo_cancel.modal.description")}
            </Typography>

            <Button
              size={"full"}
              onClick={onUndoCancelConfirm}
              className={"mt-6"}
              type={"button"}
              disabled={undoButtonDisabled}
              data-testid={"cancel-confirm-button"}
            >
              {t("services.service_contract.undo_cancel.modal.cancel")}
            </Button>

            <Box className={"nid-item-center mt-4"}>
              <Button
                variant={"link"}
                type={"button"}
                onClick={closeModal}
                disabled={undoButtonDisabled}
              >
                {t("services.service_contract.undo_cancel.modal.close")}
              </Button>
            </Box>
          </>
        )}
      </Modal>
    </>
  );
};

export default UndoCancelContract;
