import * as React from "react";

import { Box, Radio, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import RequiredTag from "@/components/ui/Tag/RequiredTag";

type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type Props = {
  testId?: string;
} & InputWithRefProps;

export const CompanyCountryField = React.forwardRef<HTMLInputElement, Props>(
  ({ testId = "company-country", ...props }, ref) => {
    const { t } = useTranslation();

    return (
      <fieldset>
        <Box className="mb-10">
          <Typography as="legend" bold className="mb-1">
            <label htmlFor="radio-company-country">
              {t("attributes.companyCountry")}
            </label>
            <RequiredTag on={true} />
          </Typography>
          <Box className="mb-1">
            <Radio
              id="radio-company-country"
              data-testid={`radio-${testId}-domestic`}
              {...props}
              label={t("attribute_values.office.domestic")}
              value="0"
              ref={ref}
            />
            <Radio
              data-testid={`radio-${testId}-overseas`}
              {...props}
              label={t("attribute_values.office.overseas")}
              value="1"
              ref={ref}
            />
          </Box>
        </Box>
      </fieldset>
    );
  },
);

CompanyCountryField.displayName = "Nid.Form.CompanyCountryField";
