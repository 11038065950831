import type * as React from "react";

import {
  FormControl,
  type OptionItem,
  Select,
  Typography,
  type UseFormRegisterReturn,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import RequiredTag from "@/components/ui/Tag/RequiredTag";

const DISPLAY_NAME = "Nid.Form.JobInfoField";

type JobInfoFieldProps = {
  testId?: string;
  errors?: {
    occupationNo?: { message?: string };
    businessNo?: { message?: string };
    jobNo?: { message?: string };
    positionNo?: { message?: string };
    employeesNo?: { message?: string };
  };
  options: {
    occupationNo: OptionItem[];
    businessNo: OptionItem[];
    jobNo: OptionItem[];
    positionNo: OptionItem[];
    employeesNo: OptionItem[];
  };
  requireOccupation: boolean;
  requireJobDetail: boolean;
  registers?: {
    occupationNo: UseFormRegisterReturn<string>;
    businessNo: UseFormRegisterReturn<string>;
    jobNo: UseFormRegisterReturn<string>;
    positionNo: UseFormRegisterReturn<string>;
    employeesNo: UseFormRegisterReturn<string>;
  };
};

const JobInfoField: React.FC<JobInfoFieldProps> = ({
  errors,
  options,
  requireOccupation,
  requireJobDetail,
  registers,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <fieldset className="nid-account-edit-fieldset">
        <Typography
          as="legend"
          size="16"
          bold
          className="mb-2"
          color={errors?.occupationNo ? "red90" : undefined}
        >
          <label htmlFor="select-occupation-no">
            {t("attributes.occupation")}
          </label>
          <RequiredTag on={requireOccupation} />
        </Typography>
        <Select
          name="occupation-no"
          options={options.occupationNo}
          data-testid="select-occupation-no"
          containerclassname="w-full mb-1"
          status={errors?.occupationNo ? "error" : undefined}
          {...registers?.occupationNo}
        />
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.occupationNo ? "error" : undefined}
          data-testid="error-occupation"
        >
          {errors?.occupationNo?.message}
        </FormControl.Validation>
      </fieldset>

      <fieldset className="nid-account-edit-fieldset">
        <Typography
          as="legend"
          size="16"
          bold
          className="mb-2"
          color={
            errors?.businessNo ||
            errors?.jobNo ||
            errors?.positionNo ||
            errors?.employeesNo
              ? "red90"
              : undefined
          }
        >
          <label htmlFor="select-business-no">
            {t("personal.edit.job_info.text.detail")}
          </label>
          <RequiredTag on={requireJobDetail} />
        </Typography>

        <Select
          name="businessNo"
          options={options.businessNo}
          data-testid="select-business-no"
          containerclassname="w-full mb-2"
          status={errors?.businessNo ? "error" : undefined}
          disabled={!requireJobDetail}
          {...registers?.businessNo}
        />

        <Select
          name="jobNo"
          options={options.jobNo}
          data-testid="select-job-no"
          containerclassname="w-full mb-2"
          status={errors?.jobNo ? "error" : undefined}
          disabled={!requireJobDetail}
          {...registers?.jobNo}
        />

        <Select
          name="positionNo"
          options={options.positionNo}
          data-testid="select-position-no"
          containerclassname="w-full mb-2"
          status={errors?.positionNo ? "error" : undefined}
          disabled={!requireJobDetail}
          {...registers?.positionNo}
        />

        <Select
          name="employeesNo"
          options={options.employeesNo}
          data-testid="select-employees-no"
          containerclassname="w-full mb-1"
          status={errors?.employeesNo ? "error" : undefined}
          disabled={!requireJobDetail}
          {...registers?.employeesNo}
        />

        <FormControl.Validation
          className="nid-error-field"
          status={
            errors?.businessNo ||
            errors?.jobNo ||
            errors?.positionNo ||
            errors?.employeesNo
              ? "error"
              : undefined
          }
          data-testid="error-job"
        >
          {errors?.businessNo?.message ||
            errors?.jobNo?.message ||
            errors?.positionNo?.message ||
            errors?.employeesNo?.message}
        </FormControl.Validation>
      </fieldset>
    </>
  );
};

JobInfoField.displayName = DISPLAY_NAME;
const Root = JobInfoField;
export { Root, JobInfoField };
export type { JobInfoFieldProps };
export default JobInfoField;
