import type React from "react";

import { Outlet } from "react-router";

import { AuthenticatorMfaSetupTokenProvider } from "@/features/Security/Mfa/Authenticator/AuthenticatorMfaSetupTokenProvider";
import { LoginWallProvider } from "nid-common";

const SecurityMfaAuthenticatorPageWrapper: React.FC = () => {
  return (
    <LoginWallProvider maxAge={600}>
      <AuthenticatorMfaSetupTokenProvider>
        <Outlet />
      </AuthenticatorMfaSetupTokenProvider>
    </LoginWallProvider>
  );
};

export default SecurityMfaAuthenticatorPageWrapper;
