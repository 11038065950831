import type { AtDateTime } from "@/components/ui/Passkeys/PasskeyItem";
import aaguidDataJson from "./aaguid.json";

/**
 * aaguidInfoFn
 * @param aaguid
 * @returns
 */
export const aaguidInfoFn = (aaguid: string): { name: string } | undefined => {
  return aaguid in aaguidDataJson
    ? aaguidDataJson[aaguid as keyof typeof aaguidDataJson]
    : undefined;
};

export function convertToAtDateTime(date: Date): AtDateTime {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    date: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
  };
}
