import { Button, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

export type PasskeyInformationModalContentProps = {
  id: "already_registered" | undefined;
  closeModal: () => void;
};

export const PasskeyInformationModalContent: React.FC<
  PasskeyInformationModalContentProps
> = ({ id, closeModal }) => {
  const { t } = useTranslation();

  const description = id && t(`security.passkey.info.${id}.description`);
  const buttonLabel = id && t(`security.passkey.info.${id}.button`);
  return (
    <>
      <Typography as="span" data-testid={"passkey-information-dialog-content"}>
        {description}
      </Typography>

      <Button
        size={"full"}
        onClick={closeModal}
        data-testid={"button-close-passkey-information-dialog"}
        type="button"
        className={"mt-6"}
      >
        {buttonLabel}
      </Button>
    </>
  );
};
