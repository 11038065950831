import { useTranslation } from "react-i18next";

import { Outlet, useLocation } from "react-router";

import { Layout } from "@/components/ui/Layout";
import { BackupCodeProvider } from "@/providers/SecurityBackupCodeProvider";
import { path } from "@/routes";

import { Link, Typography } from "nikkei-ui";

export const SecurityMfaPageWrapper = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isBackupCodePage = pathname === path.security.mfa.backupCode;

  const layoutClass = isBackupCodePage
    ? "security-layout security-backupcode-layout"
    : "security-layout";
  const breadcrumbText = isBackupCodePage
    ? "breadcrumbs.security_mfa_backup_code"
    : "breadcrumbs.security_mfa_enabled";
  return (
    <Layout
      title={t(breadcrumbText)}
      layoutClassName={layoutClass}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.security.root} size="14">
            {t("breadcrumbs.security")}
          </Link>
          {isBackupCodePage ? (
            <Link to={path.security.mfa.root} size="14">
              {t("breadcrumbs.security_mfa_enabled")}
            </Link>
          ) : null}
          <Typography as="span" size="14">
            {t(breadcrumbText)}
          </Typography>
        </>
      }
      description={t("security.description")}
    >
      <BackupCodeProvider>
        <Outlet />
      </BackupCodeProvider>
    </Layout>
  );
};
