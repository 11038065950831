import type React from "react";
import styles from "./AccountKeyIcon.module.css";

export const AccountKeyIcon: React.FC<
  React.ComponentProps<"svg"> & { color: "blue120" | "white" }
> = (props) => {
  const { className, ...others } = props;
  const color = styles[props.color];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      aria-label="Icon of account with key"
      aria-hidden="true"
      className={`${className} ${color}`}
      {...others}
    >
      <path d="M3 20V17.2C3 16.6333 3.14583 16.1125 3.4375 15.6375C3.72917 15.1625 4.11667 14.8 4.6 14.55C5.63333 14.0333 6.68333 13.6458 7.75 13.3875C8.81667 13.1292 9.9 13 11 13C11.3333 13 11.6667 13.0125 12 13.0375C12.3333 13.0625 12.6667 13.1 13 13.15C12.9333 14.1167 13.1083 15.0292 13.525 15.8875C13.9417 16.7458 14.55 17.45 15.35 18V20H3ZM19 23L17.5 21.5V16.85C16.7667 16.6333 16.1667 16.2208 15.7 15.6125C15.2333 15.0042 15 14.3 15 13.5C15 12.5333 15.3417 11.7083 16.025 11.025C16.7083 10.3417 17.5333 10 18.5 10C19.4667 10 20.2917 10.3417 20.975 11.025C21.6583 11.7083 22 12.5333 22 13.5C22 14.25 21.7875 14.9167 21.3625 15.5C20.9375 16.0833 20.4 16.5 19.75 16.75L21 18L19.5 19.5L21 21L19 23ZM11 12C9.9 12 8.95833 11.6083 8.175 10.825C7.39167 10.0417 7 9.1 7 8C7 6.9 7.39167 5.95833 8.175 5.175C8.95833 4.39167 9.9 4 11 4C12.1 4 13.0417 4.39167 13.825 5.175C14.6083 5.95833 15 6.9 15 8C15 9.1 14.6083 10.0417 13.825 10.825C13.0417 11.6083 12.1 12 11 12ZM18.5 14C18.7833 14 19.0208 13.9042 19.2125 13.7125C19.4042 13.5208 19.5 13.2833 19.5 13C19.5 12.7167 19.4042 12.4792 19.2125 12.2875C19.0208 12.0958 18.7833 12 18.5 12C18.2167 12 17.9792 12.0958 17.7875 12.2875C17.5958 12.4792 17.5 12.7167 17.5 13C17.5 13.2833 17.5958 13.5208 17.7875 13.7125C17.9792 13.9042 18.2167 14 18.5 14Z" />
    </svg>
  );
};
