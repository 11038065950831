import { Box, Typography } from "nikkei-ui";
import type React from "react";

import styles from "./Cautions.module.css";

type CautionListItemProps = {
  text: string;
};

type CautionsProps = {
  cautionTitle?: string;
  cautionList: CautionListItemProps[];
  hasMarginBottom?: boolean;
};

export const Cautions: React.FC<CautionsProps> = ({
  cautionTitle,
  cautionList,
  hasMarginBottom = true,
}) => {
  const marginBottom0 = hasMarginBottom ? "" : "mb-0";
  return (
    <Box
      as="section"
      className={`nid-section nid-section-overflow ${marginBottom0}`}
    >
      <Box
        className={`nid-section-inner ${cautionTitle && styles.innerWithTitle}`}
      >
        {cautionTitle && (
          <Typography as="h3" bold size="14">
            {cautionTitle}
          </Typography>
        )}
        <ul>
          {cautionList.map((item) => (
            <li key={item.text} className={styles.list}>
              <Typography as="span">{item.text}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
