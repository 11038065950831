import { Box, ButtonBase as Button, TextField } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { usePersonalEmailChangeInputFeature } from "@/features/Personal/EmailChange/hooks/usePersonalEmailChangeInputFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const PersonalEmailChangeInputFeature = (): React.JSX.Element => {
  const { t } = useTranslation();

  const { email, errors, handleSubmit, registers, status, buttonEnabled } =
    usePersonalEmailChangeInputFeature();

  if (status !== "ok") return <ContentSkeletonLoader />;

  const errorMessage = errors?.email?.message;

  return (
    <Box as="section" className="nid-section nid-section-without-background">
      <Box className="nid-section-inner">
        <Box className="nid-section-header">
          <TextField
            labelVariant="head"
            variant="readOnlyText"
            leadingIcon="Mail"
            readOnly={true}
            label={t("attributes.current_email")}
            value={email}
            data-testid="input-current-email"
            className="nid-background-transparent mb-2"
          />
        </Box>
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            labelVariant="head"
            label={t("attributes.new_email")}
            variant="email"
            autoFocus={true}
            className="mb-2"
            testId="input-new-email"
            status={errorMessage ? "error" : undefined}
            validationMessage={errorMessage}
            placeholder="example@example.com"
            required={true}
            {...registers.email}
          />
          <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
            {t("personal.edit.button.send_email")}
          </Button>
        </form>
        <Box className="nid-item-center">
          <BackButton
            to={path.personal.root}
            buttonText={t("personal.edit.button.cancel")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalEmailChangeInputFeature;
