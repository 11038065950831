import { Box, Typography } from "nikkei-ui";
import type React from "react";

type ContractNoticeProps = {
  title?: string;
  children: React.ReactNode;
  testId?: string;
};

export const ContractNotice: React.FC<ContractNoticeProps> = ({
  title,
  children,
  testId,
}): React.JSX.Element => {
  return (
    <>
      <hr className="nid-separator w-full" />
      <Box className="pt-3 pb-3">
        <Box
          className="nid-column-item-start nid-section-inner-gap4"
          data-testid={testId}
        >
          {title && (
            <Typography as="h3" size="14">
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Box>
    </>
  );
};
