import { Box, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import styles from "../CardPaymentHistories.module.css";

type InvoiceServiceItemProps = {
  issuer: string;
  invoiceNo: string;
};

export const PaymentStatementIssuer = ({
  issuer,
  invoiceNo,
}: InvoiceServiceItemProps) => {
  const { t } = useTranslation();
  const className = `${styles.invoiceBg} nid-section nid-section-overflow mb-0`;
  return (
    <Box as="section" className={className}>
      <Box className="nid-section-inner">
        <Box className={`${styles.invoiceBlock} print-invoice-issuer`}>
          <Typography as="p" size="14">
            {issuer}
          </Typography>

          <Typography as="p" size="14">
            {t("payments.histories.detail.issue.invoice", { invoiceNo })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
