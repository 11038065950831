import { useAccountSessionStorage } from "@/hooks/useAccountSessionStorage";

type CardUpdateAppliedService = {
  serviceId: string;
  name: string;
  planName?: string;
};

export const usePaymentUpdateCompletePageAssets = () => {
  const { setValue, value } = useAccountSessionStorage<
    | {
        services: CardUpdateAppliedService[];
        backButtonLink: string | undefined;
      }
    | undefined
  >("usePaymentUpdateCompletePageAssets", undefined);

  return {
    setValue,
    value,
  };
};
