import type React from "react";

import { ArrowForwardIcon, Box, Typography } from "nikkei-ui";
import { Link, useLocation, useMatch } from "react-router";

type NavItem = {
  title: string;
  to: string;
  supportText?: string;
  testId?: string;
};
type SidebarProps = {
  navList: NavItem[];
};

const includesWithoutLeadingSlash = (str: string, searchString: string) => {
  const replacedStr = searchString.replace(/^\//, "");
  if (!replacedStr) {
    return false;
  }
  return str.includes(replacedStr);
};

const Sidebar: React.FC<SidebarProps> = ({ navList }) => {
  const isRootPath = useMatch("/");
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <ul className="nid-sidebar-ul">
      {navList.map((item, index) => {
        const isActiveRoot = isRootPath && index === 0;
        const isActive = includesWithoutLeadingSlash(currentPath, item.to);
        const activeLinkClass =
          isActive || isActiveRoot
            ? "nid-sidebar-item--active"
            : "nid-sidebar-item";

        return (
          <li key={item.title} className="nid-list-item nid-sidebar-li">
            <Link
              to={item.to}
              className={activeLinkClass}
              data-testid={item.testId}
            >
              <Box>
                <Typography
                  size="16"
                  lineHeight="165"
                  as="p"
                  color="gray120"
                  bold
                >
                  {item.title}
                </Typography>
                {item.supportText && (
                  <Typography
                    size="13"
                    lineHeight="135"
                    as="span"
                    color="gray90"
                  >
                    {item.supportText}
                  </Typography>
                )}
              </Box>
              <ArrowForwardIcon
                color={isActive || isActiveRoot ? "blue90" : "gray90"}
              />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

Sidebar.displayName = "Nid.Sidebar";
export { Sidebar };
export type { SidebarProps };
export default Sidebar;
