import type React from "react";

import { Box, ButtonBase as Button, FormControl } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { PersonalConfirmItem } from "@/components/ui/PersonalConfirmItem/PersonalConfirmItem";
import { path } from "@/routes";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { ContentSkeletonLoader } from "nid-common";
import { usePersonalInfoConfirmFeature } from "./hooks/usePersonalInfoConfirmFeature";

export const PersonalInfoConfirmFeature: React.FC = () => {
  const r = usePersonalInfoConfirmFeature();
  const { t } = useTranslation();

  const { getDisplayValues, handleSubmit, formState, apiError, inputDone } = r;

  if (r.status !== "ok" || !inputDone) {
    return <ContentSkeletonLoader />;
  }

  const displayValues = getDisplayValues();

  return (
    <form onSubmit={handleSubmit}>
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <Box>
            <PersonalConfirmItem
              values={[
                {
                  key: t("attributes.name"),
                  values: displayValues.fullName,
                },
                {
                  key: t("attributes.nameKana"),
                  values: displayValues.fullNameKana,
                },
                {
                  key: t("attributes.birth"),
                  values: displayValues.birth,
                },
                {
                  key: t("attributes.sex"),
                  values: displayValues.sex,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <FormControl.Validation
          className="nid-error-field"
          status={apiError ? "error" : undefined}
          data-testid="error-api"
        >
          {apiError}
        </FormControl.Validation>
      </Box>

      <Box className="nid-confirm-submitarea">
        <Button
          size="full"
          data-testid="submit"
          disabled={formState.isSubmitting || Boolean(apiError)}
        >
          {t("personal.edit.button.submit")}
        </Button>
        <Box className="nid-item-center">
          {apiError ? (
            <BackButton
              to={path.personal.root}
              buttonText={t("personal.edit.button.back_to_personal")}
            />
          ) : (
            <BackButton
              to={path.personal.info.root}
              buttonText={t("personal.edit.button.edit")}
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

export default PersonalInfoConfirmFeature;
