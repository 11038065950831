import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import PersonalJobInfoInputFeature from "@/features/Personal/JobInfo/PersonalJobInfoInputFeature";

export const PersonalJobPage = (): React.JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <ArticleTitle
        testId="article-personal-job-info-input"
        title={t("breadcrumbs.personal_job_info")}
      />
      <PersonalJobInfoInputFeature />
    </>
  );
};

export default PersonalJobPage;
