import * as React from "react";
import { useImperativeHandle } from "react";

import { Box, Checkbox, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import RequiredTag from "../../Tag/RequiredTag";

export type NewsSubscriptionFormValues = {
  newsSubscription1: boolean;
  newsSubscription3: boolean;
};

type HTMLNewsSubscriptionFieldElement = {
  name: string;
  onChange?: (event: {
    target: { name: string; value: NewsSubscriptionFormValues };
  }) => void;
  value?: NewsSubscriptionFormValues;
};

type Props = {
  testId?: string;
} & HTMLNewsSubscriptionFieldElement;

export const NewsSubscriptionField = React.forwardRef<
  HTMLNewsSubscriptionFieldElement,
  Props
>(({ testId = "news-subscription", name, onChange }, ref) => {
  const { t } = useTranslation();

  const newsSubscription1Ref = React.useRef<HTMLInputElement>(null);
  const newsSubscription3Ref = React.useRef<HTMLInputElement>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        set value(x: NewsSubscriptionFormValues) {
          if (newsSubscription1Ref.current)
            newsSubscription1Ref.current.checked = x.newsSubscription1;
          if (newsSubscription3Ref.current)
            newsSubscription3Ref.current.checked = x.newsSubscription3;
        },
        name: name,
      } as HTMLNewsSubscriptionFieldElement;
    },
    [newsSubscription1Ref, newsSubscription3Ref],
  );

  const onChangeElement = () => {
    if (onChange) {
      const value: NewsSubscriptionFormValues = {
        newsSubscription1: newsSubscription1Ref.current?.checked || false,
        newsSubscription3: newsSubscription3Ref.current?.checked || false,
      };
      onChange({ target: { name, value } });
    }
  };

  return (
    <>
      <Typography as="legend" bold className="mb-2">
        {t("attributes.news_subscription")}
        <RequiredTag on={false} />
      </Typography>
      <Box className="mb-9">
        <Checkbox
          data-testid={`${testId}-news-subscription1`}
          label={t("attribute_values.news_subscription.1")}
          id="news-subscription1"
          ref={newsSubscription1Ref}
          onChange={onChangeElement}
        />
        <Checkbox
          data-testid={`${testId}-news-subscription3`}
          label={t("attribute_values.news_subscription.3")}
          id="news-subscription3"
          ref={newsSubscription3Ref}
          onChange={onChangeElement}
        />
      </Box>
    </>
  );
});

NewsSubscriptionField.displayName = "Nid.Form.NewsSubscriptionField";
