import type React from "react";
import { useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

import type { InterestFieldValues } from "@/components/ui/Form/InterestField/InterestField";
import type { NewsSubscriptionFormValues } from "@/components/ui/Form/NewsSubscriptionField/NewsSubscriptionField";
import type { NotificationMailFieldValue } from "@/components/ui/Form/NotificationMailField/NotificationMailField";
import { type UserInfoPatch, usePatchUserInfo } from "@/hooks/usePatchUserInfo";

export type PersonalSurveyFormValues = {
  notificationMail: NotificationMailFieldValue;
  newsSubscription: NewsSubscriptionFormValues;
  nikkeiResearchMonitor: boolean;
  interest: InterestFieldValues;
  inputDone: boolean;
};

export const buildUserInfoPatch = (
  input: PersonalSurveyFormValues,
): UserInfoPatch => {
  return {
    receiveNikkeiMail: input.notificationMail.nikkeiMail,
    receiveThirdPartyMail: input.notificationMail.thirdPartyMail,
    registerNikkeiMonitor: input.nikkeiResearchMonitor,
    newsSubscriptions: {
      newsSubscription1: input.newsSubscription.newsSubscription1,
      newsSubscription3: input.newsSubscription.newsSubscription3,
    },
    interest: {
      interest1: input.interest.interest1,
      interest2: input.interest.interest2,
      interest3: input.interest.interest3,
      interest4: input.interest.interest4,
      interest5: input.interest.interest5,
      interest6: input.interest.interest6,
      interest7: input.interest.interest7,
      interest8: input.interest.interest8,
      interest9: input.interest.interest9,
      interest10: input.interest.interest10,
      interest11: input.interest.interest11,
      interest12: input.interest.interest12,
      interest13: input.interest.interest13,
    },
  } as UserInfoPatch;
};

export const PersonalSurveyFormProvider: React.FC<{
  children: React.ReactElement;
}> = (props) => {
  const patchUserInfo = usePatchUserInfo();
  const methods = useForm<PersonalSurveyFormValues>({
    mode: "onBlur",
    shouldUnregister: false,
    defaultValues: {
      notificationMail: {
        nikkeiMail: false,
        thirdPartyMail: false,
      },
      newsSubscription: {
        newsSubscription1: false,
        newsSubscription3: false,
      },
      nikkeiResearchMonitor: false,
      interest: {
        interest1: false,
        interest2: false,
        interest3: false,
        interest4: false,
        interest5: false,
        interest6: false,
        interest7: false,
        interest8: false,
        interest9: false,
        interest10: false,
        interest11: false,
        interest12: false,
        interest13: false,
      },
      inputDone: false,
    },
  });

  useEffect(() => {
    if (patchUserInfo.status !== "ok") return;
    const userInfo = patchUserInfo.userInfo;
    methods.setValue("notificationMail", {
      nikkeiMail: userInfo.receiveNikkeiMail || false,
      thirdPartyMail: userInfo.receiveThirdPartyMail || false,
    });
    methods.setValue("newsSubscription", {
      newsSubscription1: userInfo.newsSubscriptions?.newsSubscription1 || false,
      newsSubscription3: userInfo.newsSubscriptions?.newsSubscription3 || false,
    });
    methods.setValue(
      "nikkeiResearchMonitor",
      userInfo.registerNikkeiMonitor || false,
    );
    methods.setValue("interest", {
      interest1: userInfo.interest?.interest1 || false,
      interest2: userInfo.interest?.interest2 || false,
      interest3: userInfo.interest?.interest3 || false,
      interest4: userInfo.interest?.interest4 || false,
      interest5: userInfo.interest?.interest5 || false,
      interest6: userInfo.interest?.interest6 || false,
      interest7: userInfo.interest?.interest7 || false,
      interest8: userInfo.interest?.interest8 || false,
      interest9: userInfo.interest?.interest9 || false,
      interest10: userInfo.interest?.interest10 || false,
      interest11: userInfo.interest?.interest11 || false,
      interest12: userInfo.interest?.interest12 || false,
      interest13: userInfo.interest?.interest13 || false,
    });
  }, [patchUserInfo.status]);

  return <FormProvider {...methods}>{props.children}</FormProvider>;
};
