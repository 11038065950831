import type * as React from "react";

import {
  Box,
  ExternalLinkIcon,
  InfoCircleFillIcon,
  Link,
  LinkButton,
  Typography,
} from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { path } from "@/routes";

import { Layout } from "../../components/ui/Layout";

import styles from "./index.module.css";

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.error")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Typography as="span" size="14">
            {t("breadcrumbs.error")}
          </Typography>
        </>
      }
    >
      <ArticleTitle testId="article-error" title={t("error.error.title")}>
        <Typography as="p">{t("error.error.text")}</Typography>
      </ArticleTitle>

      <Box className="nid-article-header-inner my-10">
        <LinkButton to={path.root} size="full">
          {t("error.error.button.back")}
        </LinkButton>
      </Box>

      <Box className={`nid-section-inner ${styles.support}`}>
        <Box className={"nid-row mb-4"}>
          <InfoCircleFillIcon className={"mr-1"} />
          <Typography bold>{t("error.error.when_repeated.title")}</Typography>
        </Box>

        <Typography size={"14"} className={"mb-4"}>
          {t("error.error.when_repeated.message")}
        </Typography>

        <Link href={import.meta.env.VITE_URL_CUSTOMER_SUPPORT} newTab>
          <Box className={"nid-row"}>
            {t("error.error.when_repeated.link")} <ExternalLinkIcon />
          </Box>
        </Link>
      </Box>
    </Layout>
  );
};
