import type React from "react";

import { Box, Typography } from "nikkei-ui";

import "./backupCodeCard.css";

type BackupCodeProps = {
  backupCode: string[];
};

export const BackupCodeCard: React.FC<BackupCodeProps> = ({ backupCode }) => {
  return (
    <Box className="code-card">
      <Box className="code-card-grid">
        {backupCode.map((code, index) => {
          return (
            <Box key={code} className="code-card-item">
              <Typography size="20" bold data-testid={`backup-code-${index}`}>
                {code}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
