import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  type PatchUserInfoError,
  type UserInfoPatch,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { useUserInfoLabel } from "@/hooks/useUserInfoLabel";

import { path } from "@/routes";

import { useUserInfo } from "@/hooks/useUserInfo";
import { useUserStatus } from "nid-common";
import {
  type InputUserInfoValues,
  buildUserInfoPatch,
} from "../PersonalInfoFormProvider";

export const usePersonalInfoConfirmFeature = () => {
  const { t } = useTranslation();
  const { handleSubmit, getValues, formState } =
    useFormContext<InputUserInfoValues>();
  const { getBirthLabel, getSexLabel } = useUserInfoLabel();
  const { patch, userInfo: confirmUserInfo } = usePatchUserInfo();
  const { mutate: mutateUserStatus } = useUserStatus();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string>();
  const { userInfo, status } = useUserInfo();

  if (status === "loading") {
    return { status: "loading" } as const;
  }
  if (status === "error") {
    return { status: "error" } as const;
  }

  if (confirmUserInfo === undefined) {
    navigate(path.personal.info.root, { replace: true });
    return { status: "ok" } as const;
  }

  // note: inputでsubmitされた値を利用してInputUserInfoValuesを作成
  // ブラウザバックで戻ってきた場合に、inputでsubmitされた値を再度表示するため
  const confirmUserInfoValues = (
    patchUserInfo: UserInfoPatch,
  ): InputUserInfoValues => {
    const inputDone = getValues("inputDone");
    return {
      name: {
        first: patchUserInfo.firstName ?? "",
        last: patchUserInfo.lastName ?? "",
      },
      nameKana: {
        first: patchUserInfo.firstNameKana ?? "",
        last: patchUserInfo.lastNameKana ?? "",
      },
      birth: patchUserInfo.birth
        ? {
            year: String(patchUserInfo.birth.year),
            month: String(patchUserInfo.birth.month),
            date: String(patchUserInfo.birth.date),
          }
        : undefined,
      sex: patchUserInfo.sex ?? undefined,
      inputDone,
    };
  };

  const getDisplayValues = () => {
    const values = confirmUserInfoValues(confirmUserInfo);
    return {
      fullName:
        values.name.last && values.name.first
          ? `${values.name.last}　${values.name.first}`
          : values.name.last || values.name.first,
      fullNameKana:
        values.nameKana.last && values.nameKana.first
          ? `${values.nameKana.last}　${values.nameKana.first}`
          : values.nameKana.last || values.nameKana.first,
      birth:
        values.birth &&
        values.birth.year !== "0" &&
        values.birth.month !== "0" &&
        values.birth.date !== "0"
          ? getBirthLabel({
              year: Number(values.birth.year),
              month: Number(values.birth.month),
              date: Number(values.birth.date),
            })
          : undefined,
      sex: values.sex ? getSexLabel(values.sex) : undefined,
    };
  };

  const required = {
    birth: userInfo.birth !== undefined,
  };

  const handleConfirmSubmit: SubmitHandler<InputUserInfoValues> = async (
    _data: InputUserInfoValues,
  ) => {
    const confirmData = confirmUserInfoValues(confirmUserInfo);
    if (
      !required.birth &&
      confirmData.birth?.year === "0" &&
      confirmData.birth?.month === "0" &&
      confirmData.birth?.date === "0"
    ) {
      confirmData.birth = undefined;
    }

    try {
      await patch(buildUserInfoPatch(confirmData), false);
      mutateUserStatus();
      navigate(path.personal.info.complete);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        navigate(path.error.root);
      }
    }
  };

  const inputDone = getValues("inputDone");
  useEffect(() => {
    if (!inputDone) navigate(path.personal.info.root, { replace: true });
  }, [inputDone]);

  return {
    status: "ok",
    getDisplayValues,
    handleSubmit: handleSubmit(handleConfirmSubmit),
    formState,
    apiError,
    inputDone,
  };
};
