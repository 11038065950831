import { Box, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import styles from "../CardPaymentHistories.module.css";

export const InvoiceSeparator = () => {
  return <hr className={`nid-separator ${styles.inviceLine}`} />;
};

type InvoiceServiceItemProps = {
  title: string;
  text?: string;
  amount: string;
  hasTopLine?: boolean;
  hasBottomLine?: boolean;
  testId?: string;
};
/** 利用サービス・割引等 */
export const InvoiceServiceItem = ({
  title,
  text,
  amount,
  hasTopLine = false,
  hasBottomLine = false,
  testId = "",
}: InvoiceServiceItemProps) => {
  const { t } = useTranslation();
  const currency = t("payments.histories.detail.billing_amount.currency");
  return (
    <>
      {hasTopLine && <hr className={`nid-separator ${styles.inviceLine}`} />}
      <Box
        className={`${styles.headerAlignItemsEnd} nid-item-gap16`}
        data-testid={testId}
      >
        <Box className="nid-column-item-start" data-testid={`${testId}-title`}>
          <Typography size="14">{title}</Typography>
          {text && <Typography size="14">{text}</Typography>}
        </Box>
        <Box
          className={styles.invoiceItemAmount}
          data-testid={`${testId}-amount`}
        >
          <Typography size="14">
            {amount}
            {currency}
          </Typography>
        </Box>
      </Box>
      {hasBottomLine && <hr className={`nid-separator ${styles.inviceLine}`} />}
    </>
  );
};

export type TotalAmountProps = {
  totalAmount: number;
  tax?: number;
  taxAmount?: number;
  // 国内決済
  isDomesticPayment?: boolean;
};
/** 合計 */
export const TotalAmount = ({
  totalAmount,
  tax = 10,
  taxAmount,
  isDomesticPayment = true,
}: TotalAmountProps) => {
  const { t } = useTranslation();
  const currency = t("payments.histories.detail.billing_amount.currency");
  return (
    <>
      <hr className={`nid-separator-dark ${styles.inviceLine}`} />
      <Box className={styles.totalInvoice}>
        <Box
          className={`${styles.header} nid-item-gap16 w-full`}
          data-testid="payment-invoice-total"
        >
          <Typography as="p" bold>
            {t("payments.histories.detail.billing_amount.total")}
            <Typography as="span" size="14" bold>
              {isDomesticPayment
                ? t("payments.histories.detail.billing_amount.total_subtext", {
                    tax,
                  })
                : t(
                    "payments.histories.detail.billing_amount.total_tax_included",
                  )}
            </Typography>
          </Typography>
          <Typography as="p" size="20" bold>
            {totalAmount.toLocaleString("ja-JP")}
            {currency}
          </Typography>
        </Box>
        {isDomesticPayment && (
          <>
            <hr className={`nid-separator ${styles.inviceLine}`} />
            <Box
              className={`${styles.header} nid-item-gap16 w-full`}
              data-testid="payment-invoice-tax"
            >
              <Typography size="14">
                {t("payments.histories.detail.billing_amount.tax", { tax })}
              </Typography>
              <Typography as="p" size="14" className="nid-typography-block">
                {(taxAmount ?? 0).toLocaleString("ja-JP")}
                {currency}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
