import { useContext, useEffect, useState } from "react";

import type { AxiosError } from "axios";
import type { ParseKeys } from "i18next";

import { useUserInfo } from "@/hooks/useUserInfo";
import { path } from "@/routes";
import { useUserStatus } from "nid-common";
import {
  type ChangeLoginIdConfirmErrorResponse,
  postChangeLoginIdConfirm,
  postChangeLoginIdStart,
} from "nid-common/api/account";
import { useNavigate } from "react-router";

import { PersonalEmailChangeContext } from "../PersonalEmailChangeFormProvider";

export const usePersonalEmailChangeConfirmFeature = () => {
  const { mutate: mutateUserInfo } = useUserInfo();
  const { mutate: mutateUserStatus } = useUserStatus();
  const [email, _] = useContext(PersonalEmailChangeContext);
  const navigate = useNavigate();
  const [isResending, setIsResending] = useState(false);
  const [sendEmail, setSendEmail] = useState<"success" | undefined>(undefined);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorId, setErrorId] = useState<ParseKeys | undefined>(undefined);

  useEffect(() => {
    if (sendEmail) {
      setTimeout(() => {
        setSendEmail(undefined);
      }, 5000);
    }
  }, [sendEmail]);

  useEffect(() => {
    const checkEmail = async () => {
      if (!email)
        await navigate(path.personal.emailChange.root, { replace: true });
    };
    checkEmail();
  }, [email]);

  const onFill = async (values: string[]): Promise<void> => {
    try {
      setIsSubmitting(true);
      await postChangeLoginIdConfirm({ otp: values.join(""), login_id: email });
      mutateUserInfo();
      mutateUserStatus();
      await navigate(path.personal.emailChange.complete);
    } catch (e) {
      const response = (e as AxiosError<ChangeLoginIdConfirmErrorResponse>)
        .response;
      if (response?.status === 400 && response.data.error === "invalid_otp") {
        setErrorId("personal.edit.email_change.confirm.errors.invalid");
      } else {
        await navigate(path.error.root);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onResend = async (): Promise<void> => {
    setIsResending(true);
    const timeout = new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    try {
      await postChangeLoginIdStart({ login_id: email });
    } catch (_) {
      await navigate(path.error.root);
    }
    await timeout;
    setSendEmail("success");
    setIsResending(false);
  };

  const onBack = async () => {
    await navigate(path.personal.emailChange.root);
  };

  return {
    email,
    onFill,
    isSubmitting,
    errorId,
    onResend,
    isResending,
    sendEmail,
    onBack,
  };
};
