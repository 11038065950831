import { Box, Button, Link, Modal, useModal } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useContractRetentionPopup } from "./hooks/useContractRetentionPopup";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { path } from "@/routes";
import { useNavigate, useParams } from "react-router";
import styles from "./Contract.module.css";

type ContractNoticeProps = {
  name: string;
  testId?: string;
};

export const ContractCancelLink: React.FC<ContractNoticeProps> = ({
  name,
  testId,
}): React.JSX.Element => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { status, segmentInfo, onStartCancel } = useContractRetentionPopup(
    clientId || "",
  );
  const { registerModal, openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const cancelLink = path.services.contract.cancel.root(clientId || "");

  const openCancelModal = () => {
    onStartCancel?.();
    openModal();
  };

  const navigateToCancelPageStart = () => {
    navigate(cancelLink);
    closeModal();
  };

  if (status !== "ok") {
    return (
      <BackButton
        to={cancelLink}
        buttonText={t("services.service_contract.cancel")}
        testId={testId}
      />
    );
  }
  return (
    <>
      <BackButton
        to={"#"}
        buttonText={t("services.service_contract.cancel")}
        testId={testId}
        onClick={openCancelModal}
      />
      <Modal
        testId="contract-retention-modal"
        className={styles.retentionPopup}
        {...registerModal}
      >
        <Box className={styles.retentionPopupContainer}>
          <Link href={segmentInfo?.link_url}>
            <img src={segmentInfo?.image_url} alt={name} />
          </Link>
          <Button
            size={"full"}
            variant={"border"}
            data-testid={`contract-retention-modal-${testId}`}
            type={"button"}
            onClick={navigateToCancelPageStart}
          >
            {t("services.service_contract.popup_confirm")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
