import { useEffect, useState } from "react";

import type { AxiosError } from "axios";
import { useNavigate } from "react-router";

import { useBackupCode } from "@/providers/SecurityBackupCodeProvider";
import { path } from "@/routes";
import { getMfaBackupCode } from "nid-common/api/account";
import {
  type PostMfaBackupCodeErrorResponse,
  postMfaBackupCode,
} from "nid-common/api/account";
import useSWR from "swr";

export const useSecurityMfaBackupCodeFeature = () => {
  const navigate = useNavigate();
  const { backupCode, setBackupCode } = useBackupCode();
  const hasBackupCode: boolean = backupCode.length > 0;
  const apiReturn = useSWR(
    "account/security/mfa/backup-code",
    hasBackupCode ? () => null : getMfaBackupCode,
  );
  const [isLoading, setLoading] = useState(false);
  const [generatedBackupcode, setGeneratedBackupcode] = useState<
    "success" | "error" | undefined
  >(undefined);

  useEffect(() => {
    if (hasBackupCode) return;

    const fetchedBackpCode: string[] | undefined =
      apiReturn.data?.data?.backup_codes;
    if (fetchedBackpCode) {
      setBackupCode(fetchedBackpCode);
    }
  }, [apiReturn.data]);

  useEffect(() => {
    if (generatedBackupcode) {
      setTimeout(() => {
        setGeneratedBackupcode(undefined);
      }, 5000);
    }
  }, [generatedBackupcode]);

  const handleClickGenerateNewBackupCode = async () => {
    try {
      setLoading(true);
      const response = await postMfaBackupCode();
      setBackupCode(response.data.backup_codes);
      setGeneratedBackupcode("success");
    } catch (e) {
      const response = (e as AxiosError<PostMfaBackupCodeErrorResponse>)
        .response;
      if (
        response?.status === 400 &&
        response.data.error === "invalid_request"
      ) {
        setGeneratedBackupcode("error");
      } else {
        navigate(path.error.root);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!hasBackupCode && !apiReturn.isLoading) {
    return { status: "loading" } as const;
  }

  if (!hasBackupCode && apiReturn.error) {
    const error = apiReturn.error;
    navigate(path.error.root);
    return { status: "error", error } as const;
  }

  return {
    status: "ok",
    isLoading,
    backupCode,
    generatedBackupcode,
    handleClickGenerateNewBackupCode,
  };
};
