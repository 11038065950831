import { getCardBrand } from "@/features/Payments/Update/getCardBrand";
import { usePaymentCards, type usePaymentsType } from "@/hooks/usePaymentCards";
import { path } from "@/routes";
import type { AxiosError } from "axios";
import { getMonth, getYear } from "date-fns";
import { isHalfWithOnly, toHalfWidth } from "nid-common";
import {
  ACCOUNT_API_PAYMENT_3DS_AUTHENTICATION_REDIRECT,
  type PostUpdateCreditCardErrorResponse,
  type PostUpdateCreditCardRequest,
  postUpdateCreditCard,
} from "nid-common/api/account";
import type React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { formatExpire, formatValidateExpire } from "./utils/cardExpireFormat";
import {
  formatViewCreditCardNumber,
  sanitizeCardNumberForPost,
} from "./utils/cardFormat";
import { formatSecurityCode } from "./utils/cardSecurityCode";

// NKDK Test Card用Luhnチェックスキップ用のカード番号
// このカード番号を一回入力すると、以降の入力でLuhnチェックをスキップする
const SKIP_LUHN_CHECK_NUMBER = "0000111122223333";

const isValidLuhn = (cardNumber: string): boolean => {
  if (!/^\d+$/.test(cardNumber)) {
    return false;
  }

  const calc = (n: number): number => {
    const value = 2 * n;
    return value > 9 ? value - 9 : value;
  };

  const result = [...cardNumber].reverse().reduce((acc, digit, i) => {
    const n = Number.parseInt(digit, 10);
    const add = i % 2 === 1 ? calc(n) : n;
    return acc + add;
  }, 0);

  return result % 10 === 0;
};

type CompositionEvent = React.CompositionEvent<HTMLInputElement>;
type CompositionInputType =
  | "expireDate"
  | "cardNumber"
  | "cvc"
  | "creditCardHolderName";
export type PaymentsUpdateForm = {
  cardId: string;
  newCard: {
    number: string;
    expire: string;
    securityCode: string;
    holderName: string | undefined;
  };
  applyToOtherServices: boolean;
  agreeConsentPersonalData: boolean;
  onComposition: (e: CompositionEvent, type: CompositionInputType) => void;
};

export const usePaymentsUpdateForm = (
  haveSelectableCards: boolean,
  paymentsLoadingState: usePaymentsType["status"],
  handleToken: (token: string) => Promise<void>,
) => {
  const [skipLuhnCheck, setSkipLuhnCheck] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate: getPaymentsMutate } = usePaymentCards();

  const [errorCardType, setErrorCardType] = useState<
    "invalid_card" | "script_not_loaded" | undefined
  >(
    searchParams.get("code") === "card_expired" ||
      searchParams.get("code") === "invalid_card"
      ? "invalid_card"
      : undefined,
  );
  const [isIMEMode, setIsIMEMode] = useState(false);
  const [newCardNumber, setNewCardNumber] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<PaymentsUpdateForm>({
    mode: "onSubmit",
    defaultValues: {
      cardId:
        searchParams.get("code") === "card_expired" ||
        searchParams.get("code") === "invalid_card"
          ? "0"
          : undefined,
      newCard: {
        number: "",
        expire: "",
        securityCode: "",
        holderName: "",
      },
      applyToOtherServices: false,
      agreeConsentPersonalData: false,
      onComposition: () => {},
    },
  });

  const showNewCardForm = watch("cardId") === "0";
  const cardNumber = watch("newCard.number");
  const expiration = watch("newCard.expire");
  const securityCode = watch("newCard.securityCode");
  const holderName = watch("newCard.holderName");
  const agreeConsentPersonalData = watch("agreeConsentPersonalData");

  const handleUpdateApiRequest = async (
    request: PostUpdateCreditCardRequest,
  ) => {
    try {
      await postUpdateCreditCard(request);
      getPaymentsMutate();
      navigate(path.payments.update.complete);
    } catch (e) {
      const axiosResponse = (e as AxiosError<PostUpdateCreditCardErrorResponse>)
        .response;
      if (
        axiosResponse?.status === 400 &&
        ["invalid_card", "card_expired"].includes(axiosResponse?.data.error)
      ) {
        setErrorCardType("invalid_card");
      } else if (axiosResponse?.status === 503) {
        navigate(path.payments.maintenance);
      } else {
        navigate(path.error.root);
      }
    }
  };

  const handleRegisterApiRequest = (
    spsvToken: string,
    holderName: string,
    cardId: string,
    errorPath: string,
    serviceId?: string,
  ) => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = `${import.meta.env.VITE_ORIGIN}${ACCOUNT_API_PAYMENT_3DS_AUTHENTICATION_REDIRECT}`;

    const fields = [
      { name: "card_token", value: spsvToken },
      { name: "user_name", value: holderName },
      { name: "old_card_id", value: cardId },
      { name: "error_path", value: errorPath },
      ...(serviceId ? [{ name: "service_id", value: serviceId }] : []),
    ];

    for (const { name, value } of fields) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = name;
      input.value = value;
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  };

  useEffect(() => {
    window.setToken = async (token: string, _: string) => {
      await handleToken(token);
    };
  }, []);

  useEffect(() => {
    if (paymentsLoadingState === "ok" && !haveSelectableCards) {
      setValue("cardId", "0");
    }
  }, [paymentsLoadingState, haveSelectableCards]);

  useEffect(() => {
    if (sanitizeCardNumberForPost(cardNumber) === SKIP_LUHN_CHECK_NUMBER) {
      setSkipLuhnCheck(true);
    }
  }, [cardNumber]);

  const disableButton = !showNewCardForm
    ? watch("cardId") === undefined || watch("cardId") === null
    : !(
        cardNumber &&
        expiration &&
        securityCode &&
        holderName &&
        agreeConsentPersonalData
      );

  const handleOnChangeNewCardNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const cursor = e.target.selectionStart ?? value.length;
    const formattedValue = formatViewCreditCardNumber(value);
    setValue("newCard.number", formattedValue);
    if (
      formattedValue[cursor - 1] === " " &&
      formattedValue.length > newCardNumber.length
    ) {
      e.target.selectionStart = cursor + 1;
      e.target.selectionEnd = cursor + 1;
    } else if (
      formattedValue[cursor - 1] === " " &&
      formattedValue.length < newCardNumber.length
    ) {
      e.target.selectionStart = cursor - 1;
      e.target.selectionEnd = cursor - 1;
    } else if (cursor !== value.length) {
      e.target.selectionStart = cursor;
      e.target.selectionEnd = cursor;
    }
    setNewCardNumber(formattedValue);
  };

  const handleOnChangeNewCardExpire = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const formattedValue = formatExpire(value);
    setValue("newCard.expire", formattedValue);
  };

  const handleOnChangeNewCardSecurityCode = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const formattedValue = formatSecurityCode(value);
    setValue("newCard.securityCode", formattedValue);
  };

  const handleOnChangeNewCardHolderName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const formattedValue = toHalfWidth(e.target.value);
    setValue("newCard.holderName", formattedValue);
  };

  const createSpsvToken = async (
    newCard: PaymentsUpdateForm["newCard"],
  ): Promise<"script_not_loaded" | undefined> => {
    if (
      window.SpsvApi === undefined ||
      typeof window.SpsvApi.spsvCreateToken !== "function"
    ) {
      setErrorCardType("script_not_loaded");
      return "script_not_loaded";
    }
    const expire = formatValidateExpire(newCard.expire);
    await window.SpsvApi.spsvCreateToken(
      sanitizeCardNumberForPost(newCard.number),
      expire.year,
      expire.month,
      newCard.securityCode,
      "",
      "",
      "",
      "",
      "",
    );
  };

  const validateCardNumber = (value: string) => {
    if (getValues("cardId") !== "0") {
      return undefined;
    }
    if (!value) {
      return t("payments.update.errors.number.empty");
    }
    if (
      value.length < 14 ||
      (!skipLuhnCheck && !isValidLuhn(sanitizeCardNumberForPost(value)))
    ) {
      return t("payments.update.errors.number.invalid");
    }
  };

  const validateExpire = (month: string, year: string) => {
    if (getValues("cardId") !== "0") {
      return undefined;
    }
    if (!month || !year) {
      return t("payments.update.errors.expire.empty");
    }
    const yearNumber = Number.parseInt(year) + 2000;
    const monthNumber = Number.parseInt(month);
    const today = Date.now();
    if (yearNumber > getYear(today) + 10) {
      return t("payments.update.errors.expire.invalid");
    }
    if (yearNumber < getYear(today)) {
      return t("payments.update.errors.expire.expired");
    }
    if (yearNumber === getYear(today) && monthNumber < getMonth(today) + 1) {
      return t("payments.update.errors.expire.expired");
    }
    return undefined;
  };

  const validateSecurityCode = (value: string) => {
    if (getValues("cardId") !== "0") {
      return undefined;
    }
    if (!value || Number.isNaN(Number(value)) || value.length < 3) {
      return t("payments.update.errors.security_code");
    }
  };

  const validateName = (value: string) => {
    if (getValues("cardId") !== "0") {
      return undefined;
    }
    if (!value) {
      return t("payments.update.errors.credit_card_holder_name.empty");
    }
    if (value.length < 2 || value.length > 45) {
      return t("payments.update.errors.credit_card_holder_name.invalid_format");
    }
    if (!isHalfWithOnly(value)) {
      return t(
        "payments.update.errors.credit_card_holder_name.invalid_characters",
      );
    }
  };

  return {
    handleUpdateApiRequest,
    handleRegisterApiRequest,
    handleSubmit,
    showNewCardForm,
    watch,
    createSpsvToken,
    errorCardType,
    disableButton,
    currentCardBrand: getCardBrand(cardNumber),
    registers: {
      cardId: register("cardId", {
        onChange: () => {
          setErrorCardType(undefined);
        },
      }),
      newCard: {
        number: register("newCard.number", {
          onChange: (e) => {
            if (isIMEMode) return;
            handleOnChangeNewCardNumber(e);
          },
          validate: (value: string) => {
            return validateCardNumber(value);
          },
        }),
        expire: register("newCard.expire", {
          onChange: (e) => {
            if (isIMEMode) return;
            handleOnChangeNewCardExpire(e);
          },
          validate: (value: string) => {
            const { month, year } = formatValidateExpire(value);
            return validateExpire(month, year);
          },
        }),
        securityCode: register("newCard.securityCode", {
          onChange: (e) => {
            if (isIMEMode) return;
            handleOnChangeNewCardSecurityCode(e);
          },
          validate: (value: string) => {
            return validateSecurityCode(formatSecurityCode(value));
          },
        }),

        holderName: register("newCard.holderName", {
          validate: (value: string | undefined) => {
            if (getValues("cardId") !== "0") {
              return undefined;
            }
            if (value === undefined) {
              return t("payments.update.errors.credit_card_holder_name.empty");
            }
            return validateName(value);
          },
        }),
      },
      applyToOtherServices: register("applyToOtherServices"),
      agreeConsentPersonalData: register("agreeConsentPersonalData"),
      onComposition: (e: CompositionEvent, type: CompositionInputType) => {
        if (e.type === "compositionstart") {
          setIsIMEMode(true);
        } else if (e.type === "compositionend") {
          setIsIMEMode(false);
          // Trigger onChange
          if (type === "cardNumber") {
            handleOnChangeNewCardNumber(
              e as unknown as React.ChangeEvent<HTMLInputElement>,
            );
          }
          if (type === "expireDate") {
            handleOnChangeNewCardExpire(
              e as unknown as React.ChangeEvent<HTMLInputElement>,
            );
          }
          if (type === "cvc") {
            handleOnChangeNewCardSecurityCode(
              e as unknown as React.ChangeEvent<HTMLInputElement>,
            );
          }
          if (type === "creditCardHolderName") {
            handleOnChangeNewCardHolderName(
              e as unknown as React.ChangeEvent<HTMLInputElement>,
            );
          }
        }
      },
    },
    errors: {
      newCard: {
        number: errors.newCard?.number?.message,
        expire: errors.newCard?.expire?.message,
        securityCode: errors.newCard?.securityCode?.message,
        holderName: errors.newCard?.holderName?.message,
      },
    },
  };
};
