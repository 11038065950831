import type React from "react";

import { Box, Typography } from "nikkei-ui";

interface Props {
  title: string;
  testId: string;
  children?: React.ReactNode;
}

const ArticleTitle: React.FC<Props> = (props) => {
  const { title, testId, children } = props;

  return (
    <Box className="nid-page-title" data-testid={testId}>
      <Typography
        data-testid={`${testId}-header`}
        as="h1"
        size="24"
        bold
        className="nid-article-title"
      >
        {title}
      </Typography>
      {children && <Box data-testid={`${testId}-description`}>{children}</Box>}
    </Box>
  );
};

export default ArticleTitle;
