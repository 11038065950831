import * as React from "react";
import { useImperativeHandle } from "react";

import { Box, Checkbox, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import RequiredTag from "../../Tag/RequiredTag";

export type NotificationMailFieldValue = {
  nikkeiMail: boolean;
  thirdPartyMail: boolean;
};

type HTMLNotificationMailFieldElement = {
  name: string;
  onChange?: (event: {
    target: { name: string; value: NotificationMailFieldValue };
  }) => void;
  value?: NotificationMailFieldValue;
};

type Props = {
  testId?: string;
} & HTMLNotificationMailFieldElement;

export const NotificationMailField = React.forwardRef<
  HTMLNotificationMailFieldElement,
  Props
>(({ testId = "notification-mail", onChange, name }, ref) => {
  const { t } = useTranslation();

  const nikkeiRef = React.useRef<HTMLInputElement>(null);
  const thirdPartyRef = React.useRef<HTMLInputElement>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        set value(x: NotificationMailFieldValue) {
          if (nikkeiRef.current) nikkeiRef.current.checked = x.nikkeiMail;
          if (thirdPartyRef.current)
            thirdPartyRef.current.checked = x.thirdPartyMail;
        },
        name: name,
      } as HTMLNotificationMailFieldElement;
    },
    [nikkeiRef, thirdPartyRef],
  );

  const onChangeElement = () => {
    if (onChange) {
      const value: NotificationMailFieldValue = {
        nikkeiMail: nikkeiRef.current?.checked || false,
        thirdPartyMail: thirdPartyRef.current?.checked || false,
      };
      onChange({ target: { name, value } });
    }
  };

  return (
    <>
      <Typography as="legend" bold className="mb-2">
        {t("attributes.notification_mail")}
        <RequiredTag on={false} />
      </Typography>
      <Box className="mb-9">
        <Checkbox
          data-testid={`${testId}-nikkei-mail`}
          label={t("attributes.nikkei_mail")}
          id="nikkei-mail"
          onChange={onChangeElement}
          ref={nikkeiRef}
        />
        <Checkbox
          data-testid={`${testId}-third-party-mail`}
          label={t("attributes.third_party_mail")}
          id="third-party-mail"
          onChange={onChangeElement}
          ref={thirdPartyRef}
        />
      </Box>
    </>
  );
});

NotificationMailField.displayName = "Nid.Form.NotificationMailField";
