import type React from "react";

import { Box, Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import styles from "./BusinessServiceList.module.css";

type Props = {
  services: {
    clientId: string;
    serviceName: string;
    serviceLink?: string;
  }[];
};

export const BusinessServiceList: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Box className="nid-section nid-section-overflow">
      <Box
        className="nid-section-inner-gap0"
        data-testid="business-services-list"
      >
        <Box className={styles.header}>
          <Typography size="16" bold>
            {t("services.list.business_service")}
          </Typography>
        </Box>
        <Typography color="gray90" size="14" className="mb-2">
          {t("services.list.business_service_description")}
        </Typography>
        {props.services.map((service, index) => {
          return (
            <Box key={service.serviceName}>
              <Box className="my-3">
                {service.serviceLink ? (
                  <Link
                    href={service.serviceLink}
                    data-testid={`service-name-${service.clientId}`}
                    newTab
                  >
                    {service.serviceName}
                  </Link>
                ) : (
                  <Typography>{service.serviceName}</Typography>
                )}
              </Box>

              {index !== props.services.length - 1 && (
                <hr className="nid-separator" />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
