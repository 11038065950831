import type React from "react";

import { Box, Typography } from "nikkei-ui";

import { CreditCardIcon } from "@/components/icon/CreditCardIcon";
import type { CreditCardBrand } from "@/hooks/usePaymentCards";
import styles from "./PaymentsDeleteItem.module.css";

type PaymentsDeleteItemProps = {
  label: string;
  text: string;
  isFirst?: boolean;
  isLast?: boolean;
  brandIcon?: CreditCardBrand;
  testId?: string;
};

export const PaymentsDeleteItem: React.FC<PaymentsDeleteItemProps> = ({
  label,
  text,
  isFirst,
  isLast,
  brandIcon,
  testId,
}) => {
  const itemClass = isLast ? styles.deleteLastItem : styles.deleteItem;
  const firstItemClass = isFirst ? styles.deleteFirstItem : "";
  return (
    <>
      <Box
        className={`${itemClass} ${firstItemClass}`}
        data-testid={`payments-delete-${testId}`}
      >
        <Box className={styles.label}>
          <Typography as="label" size="14" bold>
            {label}
          </Typography>
        </Box>
        {brandIcon ? (
          <Box className="nid-row">
            <Box className={styles.brandIconBox}>
              <CreditCardIcon brand={brandIcon} className={styles.brandIcon} />
            </Box>
            <Typography as="p">{text}</Typography>
          </Box>
        ) : (
          <Typography as="p">{text}</Typography>
        )}
      </Box>
      {!isLast && <hr className="nid-separator" />}
    </>
  );
};
