import type React from "react";

import { NavigationList } from "@/components/ui/NavigationList/NavigationList";
import { path } from "@/routes";
import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";

export const PaymentsNavigation: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      as="section"
      className="nid-section nid-section-overflow-nopadding mb-6"
    >
      <Box className={"nid-section-inner"}>
        <NavigationList
          list={[
            {
              label: t("payments.navigation.histories"),
              to: path.payments.histories.root,
              testId: "link-payments-histories",
            },
          ]}
        />
      </Box>
    </Box>
  );
};
