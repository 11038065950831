import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";

import PersonalJobInfoConfirmFeature from "@/features/Personal/JobInfo/PersonalJobInfoConfirmFeature";
import { Typography } from "nikkei-ui";

const PersonalJobInfoConfirmPage = (): React.JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <ArticleTitle
        testId="article-personal-job-info-confirm"
        title={t("personal.edit.job_info.confirm.title")}
      >
        <Typography as="p" className="nid-article-description">
          {t("personal.edit.job_info.confirm.description")}
        </Typography>
      </ArticleTitle>
      <PersonalJobInfoConfirmFeature />
    </>
  );
};

export default PersonalJobInfoConfirmPage;
