import type React from "react";

import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import { Layout } from "@/components/ui/Layout";
import { PersonalOfficeFormProvider } from "@/features/Personal/Office/PersonalOfficeFormProvider";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

export const PersonalOfficePageWrapper: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.personal_office")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.personal.root} size="14">
            {t("breadcrumbs.personal")}
          </Link>
          <Typography as="span" size="14">
            {t("breadcrumbs.personal_office")}
          </Typography>
        </>
      }
      description={t("personal.description")}
    >
      <LoginWallProvider>
        <PersonalOfficeFormProvider>
          <Outlet />
        </PersonalOfficeFormProvider>
      </LoginWallProvider>
    </Layout>
  );
};
