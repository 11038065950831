import {
  type PaymentHistory,
  usePaymentHistories,
} from "@/hooks/usePaymentHistories";
import { format } from "date-fns";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

export const usePaymentHistoriesFeature = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { status, histories } = usePaymentHistories();
  const [paymentDate, setPaymentDate] = useState<
    { year: string; month: string } | undefined
  >(undefined);

  if (status === "loading") {
    return { status: "loading" } as const;
  }

  if (histories.length === 0) {
    return { status: "empty" } as const;
  }

  const selects = [
    ...new Set(histories.map((history) => format(history.salesDate, "yyyyMM"))),
  ]
    .toSorted()
    .toReversed()
    .map((date) => {
      return {
        label: t("payments.histories.select", {
          year: date.slice(0, 4),
          month: date.slice(4, 6),
        }),
        value: date,
      };
    });

  const defaultSelectValue = location.hash
    ? location.hash.substring(1)
    : selects[0].value;

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentDate({
      year: e.target.value.slice(0, 4),
      month: e.target.value.slice(4, 6),
    });
    navigate(`#${e.target.value}`, { replace: true });
  };

  const selectedPaymentDate = paymentDate || {
    year: defaultSelectValue.slice(0, 4),
    month: defaultSelectValue.slice(4, 6),
  };

  const selectedValue = `${selectedPaymentDate.year}${selectedPaymentDate.month}`;

  const displayHistories = () => {
    const targets = histories.filter((history) => {
      return (
        history.salesDate.getFullYear() === Number(selectedPaymentDate.year) &&
        history.salesDate.getMonth() + 1 === Number(selectedPaymentDate.month)
      );
    });

    const cardIdHistoryMap = targets.reduce((acc, history) => {
      const histories = [...(acc.get(history.card.id) ?? []), history];
      acc.set(history.card.id, histories);
      return acc;
    }, new Map<string, PaymentHistory[]>());

    const cardMap = histories.reduce((acc, history) => {
      acc.set(history.card.id, history.card);
      return acc;
    }, new Map<string, PaymentHistory["card"]>());

    return [...cardIdHistoryMap.entries()].map(([cardId, histories]) => {
      const card = cardMap.get(cardId);
      if (!card) {
        throw new Error("invalid data");
      }
      return [card, histories] as const;
    });
  };

  return {
    status: "ok",
    histories: [],
    selects,
    selectedValue,
    onChangeSelect,
    displayHistories: displayHistories(),
  } as const;
};
