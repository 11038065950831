import type React from "react";

import { Box, ButtonBase as Button, FormControl } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { PersonalConfirmItem } from "@/components/ui/PersonalConfirmItem/PersonalConfirmItem";
import { usePersonalOfficeConfirmFeature } from "@/features/Personal/Office/hooks/usePersonalOfficeConfirmFeature";
import { path } from "@/routes";

export const PersonalOfficeConfirmFeature: React.FC = () => {
  const { displayValues, handleSubmit, apiError, enableButton, inputDone } =
    usePersonalOfficeConfirmFeature();
  const { t } = useTranslation();

  if (!inputDone) return <></>;
  return (
    <form onSubmit={handleSubmit}>
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <Box>
            {displayValues.domestic && (
              <PersonalConfirmItem
                values={[
                  {
                    key: t("attributes.companyCountry"),
                    values: displayValues.domestic.companyLocation,
                  },
                  {
                    key: t("attributes.companyName"),
                    values: displayValues.domestic.companyName,
                  },
                  {
                    key: t("attributes.companyBusinessUnit"),
                    values: displayValues.domestic.companyBusinessUnit,
                  },
                  {
                    key: t("attributes.company_zip_code"),
                    values: displayValues.domestic.companyZipCode,
                  },
                  {
                    key: t("attributes.companyAddress"),
                    values: displayValues.domestic.companyAddress,
                  },
                  {
                    key: t("attributes.companyTel"),
                    values: displayValues.domestic.companyTel,
                  },
                ]}
              />
            )}
            {displayValues.overseas && (
              <PersonalConfirmItem
                values={[
                  {
                    key: t("attributes.companyCountry"),
                    values: displayValues.overseas.companyLocation,
                  },
                  {
                    key: t("attributes.companyName"),
                    values: displayValues.overseas.companyName,
                  },
                  {
                    key: t("attributes.companyAddress"),
                    values: displayValues.overseas.companyAddress,
                  },
                  {
                    key: t("attributes.companyTel"),
                    values: displayValues.overseas.companyTel,
                  },
                ]}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        <FormControl.Validation
          className="nid-error-field"
          status={apiError ? "error" : undefined}
          data-testid="error-api"
        >
          {apiError}
        </FormControl.Validation>
      </Box>

      <Box className="nid-confirm-submitarea">
        <Button size="full" data-testid="submit" disabled={!enableButton}>
          {t("personal.edit.button.submit")}
        </Button>
        <Box className="nid-item-center">
          {apiError ? (
            <BackButton
              to={path.root}
              buttonText={t("personal.edit.button.back_to_personal")}
            />
          ) : (
            <BackButton
              to={path.personal.office.root}
              buttonText={t("personal.edit.button.edit")}
            />
          )}
        </Box>
      </Box>
    </form>
  );
};
