import { ArrowRightIcon, Box, Link } from "nikkei-ui";

import clsx from "clsx";
import type React from "react";
import styles from "./Navigation.module.css";

interface NavigationItemProps {
  label: string;
  href?: string;
  to?: string;
  testId?: string;
  noBorder?: boolean;
  newTab?: boolean;
  state?: unknown;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  label,
  href,
  to,
  testId,
  noBorder,
  newTab,
  state,
}) => {
  return (
    <Box className={styles.itemOuter}>
      <Link
        href={href}
        to={to}
        data-testid={testId}
        newTab={newTab}
        state={state}
        linkClassName={styles.link}
      >
        <Box
          className={clsx(
            styles.navigationItem,
            !noBorder && styles.borderBottom,
          )}
        >
          {label}
          <Box className="nid-nav-icon">
            <ArrowRightIcon />
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

type NavigationListProps = {
  list: {
    label: string;
    href?: string;
    to?: string;
    testId?: string;
    state?: unknown;
    newTab?: boolean;
  }[];
  className?: string;
};

export const NavigationList: React.FC<NavigationListProps> = (props) => {
  return (
    <Box className={clsx(styles.navigationList, props.className)}>
      {props.list.map((item, index) => (
        <NavigationItem
          key={item.label}
          label={item.label}
          href={item.href}
          to={item.to}
          testId={item.testId}
          state={item.state}
          newTab={item.newTab}
          noBorder={index === props.list.length - 1}
        />
      ))}
    </Box>
  );
};
