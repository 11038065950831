import type React from "react";

import { Box, ButtonBase as Button, FormControl } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { PersonalConfirmItem } from "@/components/ui/PersonalConfirmItem/PersonalConfirmItem";
import { path } from "@/routes";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { ContentSkeletonLoader } from "nid-common";
import { usePersonalJobInfoConfirmFeature } from "./hooks/usePersonalJobInfoConfirmFeature";

export const PersonalJobInfoConfirmFeature: React.FC = () => {
  const r = usePersonalJobInfoConfirmFeature();
  const { t } = useTranslation();

  if (r.status === "loading") {
    return <ContentSkeletonLoader />;
  }
  const { displayValues, handleSubmit, apiError, buttonEnabled } = r;
  return (
    <form onSubmit={handleSubmit}>
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <Box>
            <PersonalConfirmItem
              values={[
                {
                  key: t("attributes.occupation"),
                  values: displayValues.occupation,
                },
                {
                  key: t("attributes.business"),
                  values: displayValues.business,
                },
                {
                  key: t("attributes.job"),
                  values: displayValues.job,
                },
                {
                  key: t("attributes.position"),
                  values: displayValues.position,
                },
                {
                  key: t("attributes.employees"),
                  values: displayValues.employees,
                },
                {
                  key: t("attributes.income"),
                  values: displayValues.income,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <FormControl.Validation
          className="nid-error-field"
          status={apiError ? "error" : undefined}
          data-testid="error-api"
        >
          {apiError}
        </FormControl.Validation>
      </Box>

      <Box className="nid-confirm-submitarea">
        <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
          {t("personal.edit.button.submit")}
        </Button>
        <Box className="nid-item-center">
          {apiError ? (
            <BackButton
              to={path.personal.root}
              buttonText={t("personal.edit.button.back_to_personal")}
            />
          ) : (
            <BackButton
              to={path.personal.jobInfo.root}
              buttonText={t("personal.edit.button.edit")}
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

export default PersonalJobInfoConfirmFeature;
