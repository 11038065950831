import { PaymentOrderer } from "@/components/ui/Payments/Histories/Detail/PaymentOrderer";
import { PaymentStatementIssuer } from "@/components/ui/Payments/Histories/Detail/PaymentStatementIssuer";
import { useFileOperations } from "@/hooks/useFileOperations";
import { useUserInfo } from "@/hooks/useUserInfo";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { Box, Button } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { PaymentInvoice } from "@/components/ui/Payments/Histories/Detail/PaymentInvoice";
import { InvoiceServiceItem } from "@/components/ui/Payments/Histories/Detail/PaymentInvoiceItem";
import {
  PaymentPrintCautionMessage,
  PaymentPrintMessage,
} from "@/components/ui/Payments/Histories/Detail/PaymentPrintMessage";
import { getDate, getMonth, getYear, parse } from "date-fns";
import type React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import styles from "./PaymentHistoriesFeature.module.css";
import { usePaymentHistoryDetailFeature } from "./usePaymentHistoryDetailFeature";

export type ParseDate = {
  year: number;
  month: number;
  date: number;
};
const parseToDate = (dateStr: string): ParseDate => {
  const date = parse(dateStr, "yyyyMMdd", new Date());
  return {
    year: getYear(date),
    month: getMonth(date) + 1,
    date: getDate(date),
  };
};

export const PaymentHistoryDetailFeature: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { encodedPaymentNo } = useParams();
  const useUserInfoReturn = useUserInfo();
  const userInfo = useUserInfoReturn.userInfo;
  const { handleClickPrintPage } = useFileOperations();

  useEffect(() => {
    if (!encodedPaymentNo) {
      navigate(path.payments.histories.root, { replace: true });
    }
  }, [encodedPaymentNo, navigate]);

  if (!encodedPaymentNo) {
    return <ContentSkeletonLoader />;
  }

  const { status, payment } = usePaymentHistoryDetailFeature(encodedPaymentNo);

  const billingUser = `${userInfo?.lastName ?? ""}${userInfo?.firstName ?? ""}`;

  if (status === "loading") {
    return <ContentSkeletonLoader />;
  }

  if (status === "empty" || !payment) {
    return (
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner" data-testid="detail-empty">
          {t("payments.histories.detail.empty")}
        </Box>
      </Box>
    );
  }
  const salesDate = parseToDate(payment.salesDate);
  const salesMonth = `${salesDate.year}${salesDate.month.toString().padStart(2, "0")}`;
  const changes = payment.paymentDetail?.changes ?? [];
  const hasDiscount = changes.length > 0;
  const hasTax = payment.taxRate !== undefined;

  return (
    <>
      <Box className={`${styles.content} print-section`}>
        <PaymentOrderer
          billingUser={billingUser}
          billingDate={t("payments.histories.detail.billing_date", {
            year: salesDate.year,
            month: salesDate.month,
            date: salesDate.date,
          })}
        />

        <PaymentPrintMessage year={salesDate.year} month={salesDate.month} />

        <Box as="section" className="nid-section nid-section-overflow mb-0">
          <Box className="nid-section-inner">
            <PaymentInvoice
              year={salesDate.year}
              month={salesDate.month}
              date={salesDate.date}
              totalAmount={payment.amount}
              tax={hasTax ? payment.taxRate : undefined}
              taxAmount={hasTax ? payment.taxAmount : undefined}
            >
              {/* 利用サービス */}
              <InvoiceServiceItem
                title={payment.serviceName}
                text={payment.paymentHistoryDisplayString}
                amount={
                  payment.paymentDetail?.price.toLocaleString("ja-JP") ?? "0"
                }
                testId="payment-invoice-service"
              />
              {/* 値引き・クーポン・補正 */}
              {hasDiscount &&
                changes.map((change) => (
                  <InvoiceServiceItem
                    hasTopLine
                    key={`discount-${change.changeTypeName}`}
                    title={change.changeTypeName}
                    amount={`${change.changeAmount.toLocaleString("ja-JP")}`}
                    testId={`payment-invoice-discount-${change.changeType}`}
                  />
                ))}
            </PaymentInvoice>
          </Box>
        </Box>
        <PaymentPrintCautionMessage />
        <PaymentStatementIssuer
          issuer={payment.companyName}
          invoiceNo={payment.invoiceRegistrationNo}
        />
      </Box>
      <Box className="nid-section-inner print-hidden">
        <Box className={styles.buttonGroup}>
          <Button
            size="full"
            data-testid="button-print"
            onClick={handleClickPrintPage}
          >
            {t("payments.histories.detail.button.print")}
          </Button>
          <BackButton
            to={`${path.payments.histories.root}#${salesMonth}`}
            buttonText={t("payments.histories.detail.link.back")}
            testId="link-back"
          />
        </Box>
      </Box>
    </>
  );
};
