import { usePaymentUpdateCompletePageAssets } from "@/features/Payments/Update/usePaymentUpdateCompletePageAssets";
import { usePaymentCards } from "@/hooks/usePaymentCards";
import { path } from "@/routes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  type PaymentsUpdateForm,
  usePaymentsUpdateForm,
} from "./usePaymentsUpdateForm";

export const usePaymentsUpdateCardFeature = () => {
  const navigate = useNavigate();
  const { cardId } = useParams();
  const { cards, status } = usePaymentCards();
  const currentCard = cards.findLast((card) => {
    return card.id === cardId;
  });
  const selectableCards = cards.filter((card) => {
    return card.id !== currentCard?.id && card.status === "valid";
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [spsvToken, setSpsvToken] = useState<string | undefined>(undefined);

  const [holderName, setHolderName] = useState<string>();

  const { setValue: setPaymentUpdateCompleteValues } =
    usePaymentUpdateCompletePageAssets();

  const location = useLocation();

  const {
    handleUpdateApiRequest,
    handleRegisterApiRequest,
    handleSubmit,
    registers,
    errors: formErrors,
    showNewCardForm,
    currentCardBrand,
    createSpsvToken,
    errorCardType,
    disableButton,
  } = usePaymentsUpdateForm(
    selectableCards.length > 0,
    status,
    async (token: string) => {
      setSpsvToken(token);
    },
  );

  useEffect(() => {
    const f = async () => {
      if (!spsvToken) {
        return;
      }
      handleRegisterApiRequest(
        spsvToken,
        holderName ?? "",
        currentCard?.id ?? "",
        currentCard
          ? `/account/payments/update/card/${currentCard.id}`
          : "/account/payments",
      );
    };
    f();
  }, [spsvToken]);

  useEffect(() => {
    if (
      status === "ok" &&
      (currentCard === undefined || currentCard.services.length === 0)
    ) {
      navigate(path.payments.root, { replace: true });
    }
  }, [currentCard, status]);

  if (currentCard === undefined) {
    return { status: "loading" } as const;
  }

  const onSubmit = async (
    data: Omit<PaymentsUpdateForm, "applyToOtherServices">,
  ) => {
    setIsSubmitting(true);
    const updatedServices = currentCard.services.map((service) => {
      return {
        serviceId: service.serviceId,
        name: service.name,
        planName: service.planName,
      };
    });
    setPaymentUpdateCompleteValues({
      services: updatedServices,
      backButtonLink: location.state?.destinationAfterPaymentsUpdate,
    });
    if (data.cardId === "0") {
      setHolderName(data.newCard.holderName);
      const result = await createSpsvToken(data.newCard);
      if (result === "script_not_loaded") {
        setIsSubmitting(false);
      }
      return;
    }

    await handleUpdateApiRequest({
      new_card_id: data.cardId,
      old_card_id: currentCard.id,
    });
    setIsSubmitting(false);
  };
  return {
    status: "ok",
    currentCard,
    selectableCards,
    registers: {
      cardId: registers.cardId,
      newCard: {
        number: registers.newCard.number,
        expire: registers.newCard.expire,
        securityCode: registers.newCard.securityCode,
        holderName: registers.newCard.holderName,
      },
      agreeConsentPersonalData: registers.agreeConsentPersonalData,
    },
    onComposition: registers.onComposition,
    onSubmit: handleSubmit(onSubmit),
    showNewCardForm,
    currentCardBrand,
    disableButton: disableButton || isSubmitting,
    formErrors,
    errorCardType,
  } as const;
};
