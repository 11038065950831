type Service = {
  service_name: string;
  contents: {
    title: string;
    target?: string;
    description: string;
    time?: string;
    link: string;
    link_type: "configuration" | "service_top";
  }[];
};

export const newsletterConfig: Service[] = [
  {
    service_name: "日本経済新聞 電子版",
    contents: [
      {
        title: "日経電子版 速報メール",
        target: "電子版有料会員・無料会員向け",
        description: "突発ニュースをいち早く配信します。",
        time: "配信時間：不定期（深夜・早朝に配信されることがございます）",
        link: "https://regist.nikkei.com/ds/setup/mail.do",
        link_type: "configuration",
      },
      {
        title: "日経ニュースメール",
        target: "電子版有料会員・無料会員向け",
        description:
          "電子版のトップ記事など主要なニュースや経済、企業など主要分野の記事をまとめて配信します。",
        time: "配信時間：月～金：1日3回、土・日・祝日：1日1回",
        link: "https://regist.nikkei.com/ds/setup/mail.do",
        link_type: "configuration",
      },
      {
        title: "Myニュースメール",
        target: "電子版有料会員向け",
        description: "「Myニュース」に登録した条件で集めた記事を配信します。",
        time: "配信時間：月～金：1日2回、土・日・祝日：1日1回",
        link: "https://regist.nikkei.com/ds/setup/mail.do",
        link_type: "configuration",
      },
      {
        title: "NIKKEI Briefing",
        target: "電子版有料会員・無料会員向け",
        description:
          "専門分野に深い知見を持つベテラン記者が、独自の情報と視点でニュースを読み解きます（配信頻度はテーマによって異なります）",
        link: "https://regist.nikkei.com/ds/setup/briefing.do",
        link_type: "configuration",
      },
      {
        title: "NIKKEI LIVEメール",
        target: "電子版有料会員・無料会員向け",
        description:
          "NIKKEI LIVE イベントのご案内、アーカイブ追加のお知らせをお届けします。",
        link: "https://www.nikkei.com/live/setup/mail",
        link_type: "configuration",
      },
      {
        title: "日経電子版からのご案内",
        target: "電子版有料会員・無料会員向け",
        description:
          "日経電子版の新機能紹介、今後の予告、便利な使い方、会員様に合わせた記事のご紹介や限定イベントほかお得なご優待などをお届けします（不定期）",
        link: "https://regist.nikkei.com/ds/setup/dsannai.do",
        link_type: "configuration",
      },
    ],
  },
  {
    service_name: "日経ビジネススクール",
    contents: [
      {
        title: "日経ビジネススクールメール",
        target: "利用登録会員限定（無料）",
        description:
          "ビジネスリーダーに役立つ記事やおすすめの講座・セミナー、イベント情報を、原則火曜日と金曜日にメールマガジンとしてお届けします。",
        link: "https://school.nikkei.co.jp/",
        link_type: "service_top",
      },
    ],
  },
  {
    service_name: "NIKKEI リスキリング",
    contents: [
      {
        title: "NIKKEI リスキリング メルマガ",
        description:
          "企業とビジネスパーソンのリスキリングを後押しする「NIKKEI リスキリング」のメールマガジン。企業の人材戦略や個人の学び直し・キャリア形成など、ヒントになる情報をお届けします。",
        link: "https://reskill.nikkei.com/",
        link_type: "service_top",
      },
    ],
  },
  {
    service_name: "日経BizGate",
    contents: [
      {
        title: "日経BizGateメール",
        target: "利用登録会員向け",
        description:
          "最新記事のご案内やセミナー･イベントの開催情報などを週2〜3回お届けします。",
        link: "https://bizgate.nikkei.com/",
        link_type: "service_top",
      },
    ],
  },
  {
    service_name: "日経ウーマノミクス・フォーラム",
    contents: [
      {
        title: "日経ウーマノミクス メールマガジン",
        target: "利用登録者向け",
        description:
          "日経ウーマノミクス・プロジェクトのイベントや交流会、おすすめ記事を週１回をめどにお届けします。",
        link: "https://nwp.nikkei.com/",
        link_type: "service_top",
      },
    ],
  },
  {
    service_name: "日経Gooday",
    contents: [
      {
        title: "日経Goodayメール",
        target: "利用登録会員向け",
        description:
          "「日経Gooday」の最新記事のご案内や、イベント・セミナーの開催情報などを、週２回をめどにお届けします。",
        link: "https://gooday.nikkei.co.jp/settings/register-description/",
        link_type: "service_top",
      },
    ],
  },
  {
    service_name: "Nネクストクラブ",
    contents: [
      {
        title: "Nネクストクラブ",
        target: "会員登録者向け",
        description:
          "BSテレビ東京の経済番組「NIKKEI NEWS NEXT」（月～木：夜9時～ 金：夜9時54分～）などを、一層お楽しみいただくための会員制サービスです。登録は無料です。番組キャスターやプロデューサーによるメルマガの配信の他、アンケート回答者へのプレゼント企画、イベントへの優先優待など様々な特典を用意しています。",
        link: "https://www.bs-tvtokyo.co.jp/nikkeinext/",
        link_type: "service_top",
      },
    ],
  },
  {
    service_name: "THE NIKKEI MAGAZINE",
    contents: [
      {
        title: "THE NIKKEI MAGAZINE CLUB メール",
        target: "利用登録会員限定（無料）",
        description:
          "質が高く、味わい深いモノ・コトの数々をご紹介する「THE NIKKEI MAGAZINE」のオススメ記事や一部コンテンツを会員先行でご紹介します。さらに上質なライフスタイルをテーマにした会員限定イベントへのご招待など、魅力ある特典などの情報も交えつつ週１回、お届けします。",
        link: "https://magazine.nikkei.com/info/about.html",
        link_type: "service_top",
      },
    ],
  },
];
