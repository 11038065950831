import { NavigationList } from "@/components/ui/NavigationList/NavigationList";
import { path } from "@/routes";
import { Box, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./OtherProcedures.module.css";

export const OtherProcedures: React.FC = () => {
  const { t } = useTranslation();

  const navList = [
    {
      title: t("account_top.link.payment_histories"),
      to: path.payments.histories.root,
      testId: "link-payment-histories",
    },
    {
      title: t("account_top.link.newsletters"),
      to: path.newsletters.root,
      testId: "link-newsletters",
    },
    {
      title: t("account_top.link.withdraw"),
      to: path.withdraw.root,
      testId: "link-withdraw",
    },
  ];

  return (
    <Box className={styles.otherProcedures}>
      <Box className="pl-4">
        <Typography bold>{t("account_top.title.other_rocedures")}</Typography>
      </Box>
      <NavigationList
        list={navList.map((item) => {
          return {
            label: item.title,
            to: item.to,
            testId: item.testId,
          };
        })}
      />
    </Box>
  );
};
