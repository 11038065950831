import type React from "react";

import {
  AlertMessage as AlertMessageNikkeiUi,
  Box,
  Typography,
} from "nikkei-ui";

import styles from "./AlertMessage.module.css";

type AlertProps = {
  variant?: "h5" | "h6";
  hasIcon?: boolean;
  testId?: string;
  title: string;
  containerClassName?: string;
  children?: React.ReactNode;
  withBorder?: boolean;
};

export const AlertMessage: React.FC<AlertProps> = ({
  children,
  containerClassName,
  ...props
}) => {
  const variant = props.variant || "h5";
  const hasIcon = props.hasIcon !== false;
  const withBorder = props.withBorder === undefined ? true : props.withBorder;
  return (
    <AlertMessageNikkeiUi
      variant="danger"
      withBorder={withBorder}
      className={`nid-alert-column ${containerClassName}`}
      testId={props.testId}
    >
      <Box className={styles.alertMessage}>
        <Box className="nid-row nid-item-gap4">
          {hasIcon && <AlertMessageNikkeiUi.Icon variant="danger" />}
          <Typography
            as={variant}
            data-testid={`${props.testId}-title`}
            size={variant === "h5" ? "14" : "12"}
            color="red90"
            bold
          >
            {props.title}
          </Typography>
        </Box>
        {children && (
          <Typography
            as="div"
            size="14"
            data-testid={`${props.testId}-body`}
            className="nid-typography-break-all mt-1"
            color="red90"
          >
            {children}
          </Typography>
        )}
      </Box>
    </AlertMessageNikkeiUi>
  );
};
