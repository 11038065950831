import { useAccountSessionStorage } from "@/hooks/useAccountSessionStorage";
import type React from "react";
import { type ReactElement, createContext, useContext, useMemo } from "react";

type BackupCodeContextType<T> = {
  backupCode: T;
  setBackupCode: React.Dispatch<React.SetStateAction<T>>;
  removeBackupCode?: () => void;
};

type SecurityMfaProviderProps = {
  children: ReactElement;
};

const BACKUP_CODE_STORAGE_KEY = "security-mfa-email-backup-code";

const BackupCodeContext = createContext<
  BackupCodeContextType<string[]> | undefined
>(undefined);

export const useBackupCode = () => {
  const context = useContext(BackupCodeContext);
  if (context === undefined) {
    throw new Error("useBackupCode must be used within a BackupCodeProvider");
  }
  return context;
};

export const BackupCodeProvider: React.FC<SecurityMfaProviderProps> = ({
  children,
}) => {
  const { value, setValue, removeValue } = useAccountSessionStorage<string[]>(
    BACKUP_CODE_STORAGE_KEY,
    [],
  );

  const valueState = useMemo(
    () => ({
      backupCode: value,
      setBackupCode: setValue,
      removeBackupCode: removeValue,
    }),
    [value, setValue, removeValue],
  );

  return <BackupCodeContext value={valueState}>{children}</BackupCodeContext>;
};
