import clsx from "clsx";
import type React from "react";
import styles from "./NoImage.module.css";

export const NoImage: React.FC<React.ComponentProps<"svg">> = (props) => {
  const { className, ...others } = props;
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={"Default Icon of User"}
      aria-hidden="true"
      className={clsx(className, styles.gray90)}
      {...others}
    >
      <rect y="0.545898" width="56" height="56" rx="28" />
      <path d="M30.7043 28.8067L30.2947 28.1784L29.9543 28.4003V28.8067H30.7043ZM30.7043 29.8302H29.9543V30.4638L30.579 30.5696L30.7043 29.8302ZM37.9166 37.3376V38.0876H38.6666V37.3376H37.9166ZM18.0833 37.3376H17.3333V38.0876H18.0833V37.3376ZM25.2952 29.8303L25.4205 30.5697L26.0452 30.4639V29.8303H25.2952ZM25.2952 28.8064H26.0452V28.4001L25.7049 28.1782L25.2952 28.8064ZM28 16.4626C24.8474 16.4626 22.2916 19.0183 22.2916 22.1709H23.7916C23.7916 19.8467 25.6758 17.9626 28 17.9626V16.4626ZM33.7083 22.1709C33.7083 19.0183 31.1526 16.4626 28 16.4626V17.9626C30.3242 17.9626 32.2083 19.8467 32.2083 22.1709H33.7083ZM33.7083 24.6501V22.1709H32.2083V24.6501H33.7083ZM31.1139 29.435C32.6742 28.4177 33.7083 26.6549 33.7083 24.6501H32.2083C32.2083 26.1267 31.4484 27.4263 30.2947 28.1784L31.1139 29.435ZM31.4543 29.8302V28.8067H29.9543V29.8302H31.4543ZM38.6666 35.8063C38.6666 34.1009 37.7811 32.5911 36.3858 31.4408C34.9933 30.2928 33.0601 29.4686 30.8296 29.0907L30.579 30.5696C32.6104 30.9138 34.2835 31.6516 35.4316 32.5982C36.577 33.5425 37.1666 34.6597 37.1666 35.8063H38.6666ZM38.6666 37.3376V35.8063H37.1666V37.3376H38.6666ZM18.0833 38.0876H37.9166V36.5876H18.0833V38.0876ZM17.3333 35.8063V37.3376H18.8333V35.8063H17.3333ZM25.1699 29.0908C22.9395 29.4687 21.0065 30.2929 19.614 31.4409C18.2188 32.5912 17.3333 34.101 17.3333 35.8063H18.8333C18.8333 34.6598 19.4229 33.5426 20.5682 32.5983C21.7163 31.6518 23.3892 30.9139 25.4205 30.5697L25.1699 29.0908ZM24.5452 28.8064V29.8303H26.0452V28.8064H24.5452ZM22.2916 24.6501C22.2916 26.6547 23.3255 28.4174 24.8856 29.4347L25.7049 28.1782C24.5514 27.426 23.7916 26.1266 23.7916 24.6501H22.2916ZM22.2916 22.1709V24.6501H23.7916V22.1709H22.2916Z" />
    </svg>
  );
};
