import type React from "react";
import { type ReactElement, createContext, useContext, useMemo } from "react";

import { useAccountSessionStorage } from "@/hooks/useAccountSessionStorage";
import { FormProvider, useForm } from "react-hook-form";

type SecurityMfaEmailProviderProps = {
  children: ReactElement;
};

export type SecurityMfaEmailValues = {
  emailSameAsLoginId: "true" | "false";
  email: string;
};

type MfaSetupTokenContextType<T> = {
  mfaSetupToken: T;
  setMfaSetupToken: React.Dispatch<React.SetStateAction<T>>;
  removeMfaSetupToken?: () => void;
  emailConfirmValue: string;
  setEmailConfirm: React.Dispatch<React.SetStateAction<string>>;
};

export type SecurityMfaEmailConfirmValues = {
  otp: string;
};

const EMAIL_INPUT_STORAGE_KEY = "security-mfa-email";
const EMAIL_CONFIRM_STORAGE_KEY = "security-mfa-confirm-email";
const MFA_SETUP_TOKEN_STORAGE_KEY = "security-mfa-email-mfa-setup-token";
const MfaSetupTokenContext = createContext<
  MfaSetupTokenContextType<string> | undefined
>(undefined);

export const SecurityMfaEmailCofirmFormProvider: React.FC<
  SecurityMfaEmailProviderProps
> = (props) => {
  const methods = useForm<SecurityMfaEmailConfirmValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      otp: "",
    },
  });
  return <FormProvider {...methods}>{props.children}</FormProvider>;
};

export const useEmailMfaSetupToken = () => {
  const context = useContext(MfaSetupTokenContext);
  if (context === undefined) {
    throw new Error(
      "useMfaSetupToken must be used within a MfaSetupTokenProvider",
    );
  }
  return context;
};

export const EmailMfaSetupTokenProvider: React.FC<
  SecurityMfaEmailProviderProps
> = ({ children }) => {
  const {
    value: emailConfirmValue,
    setValue: setEmailConfirm,
    removeValue: removeEmail,
  } = useAccountSessionStorage<string>(EMAIL_CONFIRM_STORAGE_KEY, "");
  const {
    value,
    setValue,
    removeValue: removeMfaSetupTokenStorage,
  } = useAccountSessionStorage<string>(MFA_SETUP_TOKEN_STORAGE_KEY, "");

  const removeMfaSetupToken = () => {
    removeMfaSetupTokenStorage();
    removeEmail();
  };

  const valueState = useMemo(
    () => ({
      mfaSetupToken: value,
      setMfaSetupToken: setValue,
      removeMfaSetupToken,
      emailConfirmValue,
      setEmailConfirm,
    }),
    [value, setValue, removeMfaSetupToken, emailConfirmValue, setEmailConfirm],
  );

  return (
    <MfaSetupTokenContext value={valueState}>{children}</MfaSetupTokenContext>
  );
};

export const SecurityMfaEmailFormProvider: React.FC<
  SecurityMfaEmailProviderProps
> = (props) => {
  const {
    value: email,
    setValue: setEmail,
    removeValue: removeEmailStorage,
  } = useAccountSessionStorage<string>(EMAIL_INPUT_STORAGE_KEY, "");

  const methods = useForm<SecurityMfaEmailValues>({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    shouldUnregister: false,
    defaultValues: {
      emailSameAsLoginId: "true",
      email: email,
    },
  });

  methods.watch((value, { name, type }) => {
    if (name === "email" && type !== "change") {
      setEmail(value.email ?? "");
    }
    if (value.email === "") {
      removeEmailStorage();
    }
  });

  return (
    <EmailMfaSetupTokenProvider>
      <FormProvider {...methods}>{props.children}</FormProvider>
    </EmailMfaSetupTokenProvider>
  );
};
