import type * as React from "react";

import { Outlet } from "react-router";

import {
  SecurityMfaEmailCofirmFormProvider,
  SecurityMfaEmailFormProvider,
} from "@/features/Security/Mfa/Email/SecurityMfaEmailFormProvider";
import { LoginWallProvider } from "nid-common";

const SecurityMfaEmailPageWrapper: React.FC = () => {
  return (
    <LoginWallProvider maxAge={600}>
      <SecurityMfaEmailCofirmFormProvider>
        <SecurityMfaEmailFormProvider>
          <Outlet />
        </SecurityMfaEmailFormProvider>
      </SecurityMfaEmailCofirmFormProvider>
    </LoginWallProvider>
  );
};

export default SecurityMfaEmailPageWrapper;
