import type React from "react";
import { createContext, useContext, useMemo } from "react";

import { useAccountSessionStorage } from "@/hooks/useAccountSessionStorage";

type Props = {
  children: React.ReactNode;
};

type AuthenticatorMfaSetupToken = {
  uri: string;
  authenticator_secret: string;
  mfa_setup_token: string;
};

const Context = createContext<
  | ReturnType<typeof useAccountSessionStorage<AuthenticatorMfaSetupToken>>
  | undefined
>(undefined);

export const AuthenticatorMfaSetupTokenProvider: React.FC<Props> = (props) => {
  const { value, setValue, removeValue } =
    useAccountSessionStorage<AuthenticatorMfaSetupToken>(
      "security-mfa-authenticator-mfa-setup-token",
      {
        uri: "",
        authenticator_secret: "",
        mfa_setup_token: "",
      },
    );

  const valueState = useMemo(
    () => ({
      value: value,
      setValue: setValue,
      removeValue: removeValue,
    }),
    [value, setValue, removeValue],
  );

  return <Context value={valueState}>{props.children}</Context>;
};

export const useAuthenticatorMfaSetupToken = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      "useAuthenticatorMfaSetupToken must be used within a AuthenticatorMfaSetupTokenProvider",
    );
  }

  return context;
};
