import type React from "react";
import { type ReactElement, createContext, useState } from "react";

type PersonalEmailChangeFormProviderProps = {
  children: ReactElement;
};

export type PersonalEmailChangeValues = {
  email: string;
};

export type EmailConfirmContextType = [
  string,
  React.Dispatch<React.SetStateAction<string>>,
];

export const PersonalEmailChangeContext =
  createContext<EmailConfirmContextType>(["", () => {}]);

export const PersonalEmailChangeFormProvider: React.FC<
  PersonalEmailChangeFormProviderProps
> = (props) => {
  const [emailConfirm, setEmailConfirm] = useState("");
  return (
    <PersonalEmailChangeContext value={[emailConfirm, setEmailConfirm]}>
      {props.children}
    </PersonalEmailChangeContext>
  );
};
