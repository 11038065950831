import { path } from "@/routes";
import type { ParseKeys } from "i18next";
import { usePasskeys, useRegisterPasskey, useRevokePasskey } from "nid-common";
import { useModal } from "nikkei-ui";
import { useState } from "react";
import { useNavigate } from "react-router";
import type { PasskeyInformationModalContentProps } from "../PasskeyInformationModalContent";
import { useSecurityPasskeyProvider } from "../SecurityPasskeyProvider";

type RevokingType = {
  item: {
    index: number;
    id: string;
    backupEligible: boolean;
    createdAt: Date;
    lastAuthenticatedAt?: Date;
    aaguid: string;
  };
  status: "success" | "error" | "confirm" | "loading";
} | null;

export const useSecurityPasskeyFeature = () => {
  const navigate = useNavigate();
  const { setPasskeyData } = useSecurityPasskeyProvider();
  const [revoking, setRevoking] = useState<RevokingType>(null);
  const [submitting, setSubmitting] = useState(false);
  const { status, data, mutate: mutatePasskey } = usePasskeys();
  const { registerPasskey, isPasskeysAvailable } = useRegisterPasskey();
  const [errorId, setErrorId] = useState<ParseKeys | undefined>(
    isPasskeysAvailable ? undefined : "security.passkey.error.not_available",
  );
  const { revokePassKey } = useRevokePasskey();
  const [info, setInfo] = useState<
    PasskeyInformationModalContentProps["id"] | undefined
  >();
  const revokeModal = useModal();
  const infoModal = useModal();

  const passkeys =
    data?.passkeys?.map((raw) => {
      return {
        id: raw.id,
        backupEligible: raw.backup_eligible,
        createdAt: new Date(raw.created_at * 1000),
        lastAuthenticatedAt: raw.last_authenticated_at
          ? new Date(raw.last_authenticated_at * 1000)
          : undefined,
        aaguid: raw.aaguid,
      };
    }) || [];

  const clearRevokingStatus = () => {
    setRevoking(null);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const handleRegisterPasskey = async () => {
    try {
      setSubmitting(true);
      const registration = await registerPasskey();
      if (registration.code !== "success") {
        if (registration.code === "user_cancel") {
          // Do nothing
        } else if (registration.code === "invalid_request") {
          setErrorId("security.passkey.error.invalid_request");
        } else if (registration.code === "already_registered") {
          setInfo("already_registered");
          infoModal.openModal();
        } else if (registration.code === "webauthn_error") {
          setErrorId("security.passkey.error.invalid_request");
        } else {
          // server_error or login_required
          navigate(path.error.root);
        }
        return;
      }
      // Set passkey data to context
      setPasskeyData({
        id: registration.data.id,
        backupEligible: registration.data.backup_eligible,
        createdAt: new Date(registration.data.created_at * 1000),
        aaguid: registration.data.aaguid,
      });
      navigate(path.security.passkeys.created);
      mutatePasskey();
    } catch (_) {
      navigate(path.error.root);
    } finally {
      setSubmitting(false);
    }
  };

  const handleRevokePassKey = async () => {
    const item = revoking?.item;
    if (!item) return;
    try {
      setSubmitting(true);
      setRevoking({ item, status: "loading" });
      await revokePassKey(item.id);
      setRevoking({ item, status: "success" });
      setErrorId(undefined);
      mutatePasskey();
    } catch (_) {
      setRevoking({ item, status: "error" });
      navigate(path.error.root);
    } finally {
      setSubmitting(false);
    }
  };

  const handleRevokePasskeyConfirm = async (revokeId: string) => {
    const index = passkeys.findIndex((item) => item.id === revokeId);
    if (index === -1) return;
    setRevoking({ item: { ...passkeys[index], index }, status: "confirm" });
    setErrorId(undefined);
    revokeModal.openModal();
  };

  const tooManyPasskeys =
    (data?.passkeys_creation_limit || 20) <= passkeys.length;
  const canSubmit = !submitting && !tooManyPasskeys && isPasskeysAvailable;

  return {
    status,
    passkeys,
    tooManyPasskeys,
    canSubmit,
    revoking,
    clearRevokingStatus,
    handleRegisterPasskey,
    handleRevokePassKey,
    handleRevokePasskeyConfirm,
    errorId,
    revokeModal: {
      register: revokeModal.registerModal,
      close: revokeModal.closeModal,
    },
    infoModal: {
      id: info,
      register: infoModal.registerModal,
      close: infoModal.closeModal,
    },
  };
};
