import type React from "react";

import {
  Box,
  FormControl,
  InputField,
  type OptionItem,
  Select,
  Textarea,
  Typography,
  type UseFormRegisterReturn,
} from "nikkei-ui";

import { useTranslation } from "react-i18next";

import CharacterError from "@/components/ui/Form/CharacterError";
import RequiredTag from "@/components/ui/Tag/RequiredTag";
import type { PersonalOfficeFormValues } from "@/features/Personal/Office/PersonalOfficeFormProvider";
import type { CompanyType } from "nid-common";

type Props = {
  testId?: string;
  companyTypes: CompanyType[];
  errors?: {
    [K in keyof PersonalOfficeFormValues["domesticCompany"]]?: {
      message?: string;
      type?: string;
    };
  };
  registers?: {
    [K in keyof PersonalOfficeFormValues["domesticCompany"]]?: UseFormRegisterReturn<string>;
  };
  companyAutoCompleteList: string[];
  onCompanyClick: () => void;
  onCompanyNameAutoCompleteSelect: (value: string) => void;
};

const DomesticCompanyField: React.FC<Props> = ({
  companyTypes,
  errors,
  registers,
  companyAutoCompleteList,
  onCompanyClick,
  onCompanyNameAutoCompleteSelect,
}) => {
  const { t } = useTranslation();

  const companyTypeOptions: OptionItem[] = [
    { label: "ーーーー", value: "0" },
    ...companyTypes.map((option) => {
      return { label: option.name, value: option.code };
    }),
  ];

  const nameError =
    errors?.companyTypeCodeFront ||
    errors?.companyTypeCodeBack ||
    errors?.companyName;

  return (
    <>
      <fieldset>
        <Box className="mb-5">
          <Typography
            as="legend"
            lineHeight="135"
            bold
            color={nameError ? "red90" : undefined}
            className="mb-2"
          >
            <label htmlFor="input-company-name">
              {t("attributes.companyName")}
            </label>
            <RequiredTag on={false} />
          </Typography>
          <Typography size="12" className="mb-2">
            {t("personal.edit.office.text.company_name_helper")}
          </Typography>
          <Select
            options={companyTypeOptions}
            name="company-type-code-front"
            data-testid="select-company-type-code-front"
            containerclassname="w-select-small"
            status={errors?.companyTypeCodeFront ? "error" : undefined}
            {...registers?.companyTypeCodeFront}
          />
          <Box className="nid-input-control-head mb-2">
            <InputField
              id="input-company-name"
              data-testid="input-company-name"
              maxLength={100}
              className="nid-input-control-head"
              status={errors?.companyName ? "error" : undefined}
              autoCompleteList={companyAutoCompleteList}
              onClick={onCompanyClick}
              autoComplete="off"
              onSelectAutoComplete={onCompanyNameAutoCompleteSelect}
              {...registers?.companyName}
            />
          </Box>
          <Select
            options={companyTypeOptions}
            name="company-type-code-back"
            data-testid="select-company-type-code-back"
            containerclassname="w-select-small mb-2"
            status={errors?.companyTypeCodeBack ? "error" : undefined}
            {...registers?.companyTypeCodeBack}
          />
          <FormControl.Validation
            className="nid-error-field"
            status={nameError ? "error" : undefined}
            data-testid="error-company-name"
          >
            {nameError?.message}
          </FormControl.Validation>
          {errors?.companyName?.type === "invalid_character" ? (
            <CharacterError />
          ) : undefined}
        </Box>
      </fieldset>

      <fieldset>
        <Box className="mb-5">
          <Typography
            as="legend"
            lineHeight="135"
            bold
            color={errors?.companyBusinessUnit ? "red90" : undefined}
            className="mb-1"
          >
            <label htmlFor="input-company-business-unit">
              {t("attributes.companyBusinessUnit")}
            </label>
            <RequiredTag on={false} />
          </Typography>
          <Box className="nid-input-control-head">
            <InputField
              id="input-company-business-unit"
              data-testid="input-company-business-unit"
              maxLength={100}
              placeholder={t(
                "personal.edit.office.placeholder.company_business_unit",
              )}
              status={errors?.companyBusinessUnit ? "error" : undefined}
              {...registers?.companyBusinessUnit}
            />
            <FormControl.Validation
              className="nid-error-field"
              status={errors?.companyBusinessUnit ? "error" : undefined}
              data-testid="error-company-business-unit"
            >
              {errors?.companyBusinessUnit?.message}
            </FormControl.Validation>
            {errors?.companyName?.type === "invalid_character" ? (
              <CharacterError />
            ) : undefined}
          </Box>
        </Box>
      </fieldset>

      <fieldset>
        <Box className="mb-5">
          <Typography
            as="legend"
            lineHeight="135"
            bold
            color={errors?.companyZipCode ? "red90" : undefined}
            className="mb-1"
          >
            <label htmlFor="input-company-zip-code">
              {t("attributes.company_zip_code")}
            </label>
            <RequiredTag on={false} />
          </Typography>
          <Box className="nid-form-postcode">
            <InputField
              id="input-company-zip-code"
              data-testid="input-company-zip-code"
              placeholder={t(
                "personal.edit.office.placeholder.company_zip_code",
              )}
              maxLength={7}
              status={errors?.companyZipCode ? "error" : undefined}
              {...registers?.companyZipCode}
            />
          </Box>
          <FormControl.Validation
            className="nid-error-field"
            status={errors?.companyZipCode ? "error" : undefined}
            data-testid="error-company-zip-code"
          >
            {errors?.companyZipCode?.message}
          </FormControl.Validation>
        </Box>
      </fieldset>

      <fieldset>
        <Box className="mb-5">
          <Typography
            as="legend"
            lineHeight="135"
            bold
            color={errors?.companyAddress ? "red90" : undefined}
            className="mb-2"
          >
            <label htmlFor="input-company-address">
              {t("attributes.companyAddress")}
            </label>
            <RequiredTag on={false} />
          </Typography>
          <Textarea
            id="input-company-address"
            data-testid="input-company-address"
            placeholder={t("personal.edit.office.placeholder.company_address")}
            className="nid-input-control-head mb-1"
            maxLength={100}
            status={errors?.companyAddress ? "error" : undefined}
            {...registers?.companyAddress}
          />
          <FormControl.Validation
            className="nid-error-field"
            status={errors?.companyAddress ? "error" : undefined}
            data-testid="error-company-address"
          >
            {errors?.companyAddress?.message}
          </FormControl.Validation>
          {errors?.companyName?.type === "invalid_character" ? (
            <CharacterError />
          ) : undefined}
        </Box>
      </fieldset>

      <fieldset>
        <Typography
          as="legend"
          lineHeight="135"
          bold
          color={errors?.companyTel ? "red90" : undefined}
          className="mb-1"
        >
          <label htmlFor="input-company-tel">
            {t("attributes.companyTel")}
          </label>
          <RequiredTag on={false} />
        </Typography>
        <Box className="nid-input-control-head">
          <InputField
            id="input-company-tel"
            data-testid="input-company-tel"
            status={errors?.companyTel ? "error" : undefined}
            {...registers?.companyTel}
          />
        </Box>
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.companyTel ? "error" : undefined}
          data-testid="error-company-tel"
        >
          {errors?.companyTel?.message}
        </FormControl.Validation>
      </fieldset>
    </>
  );
};

DomesticCompanyField.displayName = "Nid.Form.DomesticCompanyField";
const Root = DomesticCompanyField;
export { Root, DomesticCompanyField };
export default DomesticCompanyField;
