import { Box, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import styles from "../CardPaymentHistories.module.css";

type PaymentUsageDateDisplayProps = {
  billingDate: string;
};
export const PaymentUsageDateDisplay = ({
  billingDate,
}: PaymentUsageDateDisplayProps) => {
  const { t } = useTranslation();
  return (
    <Box className="nid-column-item-initial nid-item-gap8">
      <Box className="nid-column-item-start">
        <Typography as="p" size="14" data-testid={"billing-date-pc"}>
          {t("payments.histories.detail.billing_date_label_pc")}
          {billingDate}
        </Typography>
      </Box>
      <hr className={`nid-separator ${styles.inviceLine}`} />
    </Box>
  );
};
