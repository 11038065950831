import { useEffect, useState } from "react";

import type { AxiosError } from "axios";
import type { ParseKeys } from "i18next";
import { useNavigate } from "react-router";

import { useBackupCode } from "@/providers/SecurityBackupCodeProvider";
import { path } from "@/routes";
import {
  type MfaSetupAuthenticatorConfirmErrorResponse,
  postMfaSetupAuthenticatorConfirm,
} from "nid-common/api/account";

import { useAuthenticatorMfaSetupToken } from "../AuthenticatorMfaSetupTokenProvider";

export const useSecurityMfaAuthenticatorConfirmFeature = () => {
  const { value } = useAuthenticatorMfaSetupToken();
  const { setBackupCode } = useBackupCode();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorId, setErrorId] = useState<ParseKeys | undefined>(undefined);

  useEffect(() => {
    // URL直アクセス対策
    if (!value.mfa_setup_token) {
      navigate(path.root, { replace: true });
    }
  }, [value.mfa_setup_token]);

  const onFill = async (values: string[]): Promise<void> => {
    try {
      setIsSubmitting(true);
      const response = await postMfaSetupAuthenticatorConfirm({
        mfa_setup_token: value.mfa_setup_token,
        otp: values.join(""),
      });
      setBackupCode(response.data.backup_codes);
      navigate(path.security.mfa.complete);
    } catch (e) {
      const response = (
        e as AxiosError<MfaSetupAuthenticatorConfirmErrorResponse>
      ).response;
      if (response?.status === 400 && response.data.error === "invalid_otp") {
        setErrorId("security.mfa.authenticator.confirm.errors.invalid");
      } else {
        navigate(path.error.root);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = async (_: string[]): Promise<void> => {
    setErrorId(undefined);
  };

  return {
    onFill,
    onChange,
    isSubmitting,
    errorId,
  };
};
