import { path } from "@/routes";
import { Box, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { NavigationList } from "@/components/ui/NavigationList/NavigationList";

import type React from "react";
import styles from "./ServiceFeature.module.css";

const isExternalLink = (url: string) => url.startsWith("https://");

interface NavListProps {
  links: {
    label: string;
    url?: string;
    testId?: string;
    state?: {
      destinationAfterPaymentsUpdate: string;
    };
  }[];
}

export const ServiceHeader: React.FC<{
  name: string;
  logo?: string;
  description: string;
}> = (props) => {
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-0">
      <Box className="nid-section-inner-gap0">
        <Box className="nid-section-inner-header">
          {props.logo ? (
            <Box>
              <img
                src={props.logo}
                alt={props.name}
                className={styles.logo}
                data-testid="service-logo"
              />
            </Box>
          ) : (
            <Box data-testid="service-text-logo" className={styles.textLogo}>
              {props.name}
            </Box>
          )}
        </Box>
        <Box data-testid="service-description">{props.description}</Box>
      </Box>
    </Box>
  );
};

export const NavList = (props: NavListProps): React.JSX.Element => {
  return (
    <NavigationList
      list={props.links.map((item) => {
        return {
          label: item.label,
          href: item.url && isExternalLink(item.url) ? item.url : undefined,
          to: item.url && isExternalLink(item.url) ? undefined : item.url,
          state: item.state,
          newTab: Boolean(item.url && isExternalLink(item.url)),
          testId: item.testId,
        };
      })}
    />
  );
};

export const CommonNavList: React.FC = () => {
  const { t } = useTranslation();
  const navList = [
    {
      label: t("services.service.common.email"),
      url: path.personal.emailChange.root,
      testId: "link-email",
    },
    {
      label: t("services.service.common.password"),
      url: path.security.passwordChange.root,
      testId: "link-password",
    },
    {
      label: t("services.service.common.personal"),
      url: path.personal.root,
      testId: "link-personal",
    },
    {
      label: t("services.service.common.card"),
      url: path.payments.root,
      testId: "link-card",
    },
    {
      label: t("services.service.common.history"),
      url: path.payments.histories.root,
      testId: "link-history",
    },
  ];
  return (
    <Box as="section" className="nid-section nid-section-overflow-nopadding">
      <Box className="nid-section-inner">
        <Box className="nid-section-inner-header pl-4 pr-4">
          <Typography as="h2" size="18" bold color="blue120">
            <Box className="nid-section-title">
              {t("services.service.common.title")}
            </Box>
          </Typography>
          <Box as="p" className="nid-section-inner-description">
            {t("services.service.common.description")}
          </Box>
        </Box>
        <NavList links={navList} />
      </Box>
    </Box>
  );
};
