import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  type PatchUserInfoError,
  type UserInfoPatch,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { useJobInfoMaster } from "nid-common";

import { path } from "@/routes";

import {
  type PersonalJobInfoFormValues,
  buildUserInfoPatch,
} from "../PersonalJobInfoFormProvider";

import { requireDetail } from "./usePersonalJobInfoInputFeature";

export const usePersonalJobInfoConfirmFeature = () => {
  const { t } = useTranslation();
  const jobInfo = useJobInfoMaster();
  const { handleSubmit, getValues } =
    useFormContext<PersonalJobInfoFormValues>();
  const { patch, userInfo: confirmUserInfo } = usePatchUserInfo();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string>();
  const inputDone = getValues("inputDone");
  useEffect(() => {
    if (!inputDone || !confirmUserInfo)
      navigate(path.personal.jobInfo.root, { replace: true });
  }, [inputDone, jobInfo.status, confirmUserInfo, navigate]);

  if (jobInfo.status !== "ok" || !inputDone || !confirmUserInfo) {
    return { status: "loading" } as const;
  }

  // note: inputでsubmitされた値を利用してPersonalJobInfoFormValuesを作成
  // ブラウザバックで戻ってきた場合に、inputでsubmitされた値を再度表示するため
  const confirmJobInfoValues = (
    patchUserInfo: UserInfoPatch,
  ): PersonalJobInfoFormValues => {
    return {
      jobInfo: {
        occupationNo: patchUserInfo.occupation ?? "0",
        businessNo: patchUserInfo.business ?? "0",
        jobNo: patchUserInfo.job ?? "0",
        positionNo: patchUserInfo.position ?? "0",
        employeesNo: patchUserInfo.employees ?? "0",
      },
      incomeNo: patchUserInfo.income || "0",
      inputDone,
    };
  };

  const values = confirmJobInfoValues(confirmUserInfo);

  const handleConfirmSubmit: SubmitHandler<PersonalJobInfoFormValues> = async (
    _input: PersonalJobInfoFormValues,
  ) => {
    const confirmData: PersonalJobInfoFormValues =
      confirmJobInfoValues(confirmUserInfo);
    try {
      await patch(buildUserInfoPatch(confirmData, jobInfo.occupationNo), false);
      navigate(path.personal.jobInfo.complete);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        navigate(path.error.root);
      }
    }
  };

  const required = requireDetail(
    jobInfo.occupationNo,
    values.jobInfo.occupationNo,
  );
  return {
    status: "ok",
    displayValues: {
      occupation: jobInfo.occupationNo.find(
        (v) => v.code === values.jobInfo.occupationNo,
      )?.name,
      business: required
        ? jobInfo.businessNo.find((v) => v.code === values.jobInfo.businessNo)
            ?.name
        : undefined,
      job: required
        ? jobInfo.jobNo.find((v) => v.code === values.jobInfo.jobNo)?.name
        : undefined,
      position: required
        ? jobInfo.positionNo.find((v) => v.code === values.jobInfo.positionNo)
            ?.name
        : undefined,
      employees: required
        ? jobInfo.employeesNo.find((v) => v.code === values.jobInfo.employeesNo)
            ?.name
        : undefined,
      income: jobInfo.incomeNo.find((v) => v.code === values.incomeNo)?.name,
    },
    handleSubmit: handleSubmit(handleConfirmSubmit),
    apiError,
    buttonEnabled: apiError === undefined,
    inputDone,
  } as const;
};
