import type React from "react";

import { Box, Typography } from "nikkei-ui";

interface Props {
  values: {
    key: string;
    values: string | undefined | string[];
  }[];
}

export const PersonalConfirmItem: React.FC<Props> = (props) => {
  const items = props.values.map((item) => {
    return {
      key: item.key,
      values:
        typeof item.values === "string"
          ? [item.values]
          : item.values === undefined || item.values.length === 0
            ? [undefined]
            : item.values,
    };
  });

  return (
    <>
      {items.map((item, index) => {
        const itemClass =
          index !== items.length - 1
            ? "nid-confirm-item"
            : "nid-confirm-item nid-confirm-item--noborder";
        return (
          <Box
            className={itemClass}
            key={item.key}
            data-testid={`personal-confirm-item-${index}`}
          >
            <Typography
              as="label"
              size="14"
              bold
              className="nid-confirm-item-label"
            >
              {item.key}
            </Typography>
            {item.values.map((v, i) => {
              return (
                <Typography
                  as="p"
                  key={`${item.key}-${i}`}
                  size="16"
                  className="nid-confirm-item-text mt-1"
                >
                  {v}
                </Typography>
              );
            })}
          </Box>
        );
      })}
    </>
  );
};
