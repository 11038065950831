import type React from "react";

import { Box, ButtonBase as Button, FormControl } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { PersonalConfirmItem } from "@/components/ui/PersonalConfirmItem/PersonalConfirmItem";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { usePersonalAddressConfirmFeature } from "./hooks/usePersonalAddressConfirmFeature";

export const PersonalAddressConfirmFeature: React.FC = () => {
  const r = usePersonalAddressConfirmFeature();
  const { t } = useTranslation();
  const {
    getDisplayValues,
    handleSubmit,
    formState,
    apiError,
    inputDone,
    isDomestic,
  } = r;

  if (r.status === "loading" || !inputDone) {
    return <ContentSkeletonLoader />;
  }

  const displayValues = getDisplayValues();

  const zipCode = {
    key: t("personal.edit.address.confirm.attributes.zipCode"),
    values: displayValues.zipCode,
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <Box>
            <PersonalConfirmItem
              values={[
                {
                  key: t("personal.edit.address.confirm.attributes.country"),
                  values: displayValues.country,
                },
                ...(isDomestic ? [zipCode] : []),
                {
                  key: t("personal.edit.address.confirm.attributes.address"),
                  values: displayValues.address,
                },
                ...(!isDomestic ? [zipCode] : []),
                {
                  key: t("personal.edit.address.confirm.attributes.tel"),
                  values: displayValues.tel,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <FormControl.Validation
          className="nid-error-field"
          status={apiError ? "error" : undefined}
          data-testid="error-api"
        >
          {apiError}
        </FormControl.Validation>
      </Box>

      <Box className="nid-confirm-submitarea">
        <Button
          size="full"
          data-testid="submit"
          disabled={formState.isSubmitting || Boolean(apiError)}
        >
          {t("personal.edit.button.submit")}
        </Button>
        <Box className="nid-item-center">
          {apiError ? (
            <BackButton
              to={path.personal.root}
              buttonText={t("personal.edit.button.back_to_personal")}
            />
          ) : (
            <BackButton
              to={path.personal.address.root}
              buttonText={t("personal.edit.button.edit")}
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

export default PersonalAddressConfirmFeature;
