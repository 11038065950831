import { usePaymentCards } from "@/hooks/usePaymentCards";
import { path } from "@/routes";
import type { AxiosError } from "axios";
import {
  type DeleteCreditCardErrorResponse,
  deleteCreditCard,
} from "nid-common/api/account";
import { useEffect } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

type DeleteCreditCardValue = {
  cardId: string;
};

export const usePaymentsDeleteCardFeature = () => {
  const { cardId } = useParams();
  const navigate = useNavigate();
  const {
    cards,
    status: paymentsStatus,
    mutate: getPaymentsMutate,
  } = usePaymentCards();
  const selectedCard = cards.findLast((card) => {
    return card.id === cardId;
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = useForm<DeleteCreditCardValue>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      cardId: selectedCard?.id ?? undefined,
    },
  });

  useEffect(() => {
    if (paymentsStatus !== "ok") {
      return;
    }

    const isCardInvalidOrActive =
      selectedCard === undefined ||
      (selectedCard && selectedCard.services.length > 0);

    if (isCardInvalidOrActive) {
      navigate(path.payments.root, { replace: true });
    }
  }, [paymentsStatus, selectedCard, navigate]);

  if (paymentsStatus === "loading" || selectedCard === undefined) {
    return { status: "loading" } as const;
  }

  const onSubmit: SubmitHandler<DeleteCreditCardValue> = async (
    data: DeleteCreditCardValue,
  ) => {
    try {
      await deleteCreditCard({
        card_id: data.cardId,
      });
      getPaymentsMutate();
      navigate(path.payments.delete.complete);
    } catch (e) {
      const response = (e as AxiosError<DeleteCreditCardErrorResponse>)
        .response;

      if (response?.status === 503) {
        navigate(path.payments.maintenance);
      } else {
        navigate(path.error.root);
      }
    }
  };

  return {
    status: "ok",
    selectedCard,
    isSubmitting: isSubmitting,
    handleSubmit: handleSubmit(onSubmit),
    register: register("cardId", {
      required: true,
      value: selectedCard.id,
    }),
  } as const;
};
