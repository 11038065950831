import clsx from "clsx";
import type React from "react";
import styles from "./NoBrandCreditCardIcon.module.css";

export const NoBrandCreditCardIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => {
  const { className, ...others } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="24"
      viewBox="0 0 38 24"
      aria-label="Unknown Brand Credit Card"
      aria-hidden="true"
      className={clsx(className, styles.gray120)}
      {...others}
    >
      <path d="M33.7655 1.71387H4.7345C3.23075 1.71387 2 2.94462 2 4.44837V19.9794C2 21.4831 3.23075 22.7139 4.7345 22.7139H33.7655C35.2692 22.7139 36.5 21.4831 36.5 19.9794V4.44837C36.5 2.94462 35.2692 1.71387 33.7655 1.71387ZM35 19.9794C35 20.6604 34.4465 21.2139 33.7655 21.2139H4.7345C4.0535 21.2139 3.5 20.6604 3.5 19.9794V8.46387H35V19.9794ZM35 6.96387H3.5V4.44837C3.5 3.76737 4.0535 3.21387 4.7345 3.21387H33.7655C34.4465 3.21387 35 3.76737 35 4.44837V6.96387Z" />
    </svg>
  );
};
