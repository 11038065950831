import { Box, LinkButton } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { PasskeyItem } from "@/components/ui/Passkeys/PasskeyItem";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { useSecurityPasskeyCreatedFeature } from "./hooks/useSecurityPasskeyCreatedFeature";

export const SecurityPasskeyCreatedFeature = () => {
  const { t } = useTranslation();
  const r = useSecurityPasskeyCreatedFeature();

  if (r.status === "loading") {
    return <ContentSkeletonLoader />;
  }

  return (
    <Box className="nid-section-inner">
      <Box className="nid-column nid-item-gap24">
        <PasskeyItem
          title={r.title}
          createdAtDateTime={r.createdAt}
          testId={"passkey-item-created"}
        />
        <Box className="w-full">
          <LinkButton to={path.security.root} data-testid="submit" size="full">
            {t("security.passkey.created.button.back")}
          </LinkButton>
        </Box>
      </Box>
    </Box>
  );
};
