import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_CONTRACT_CANCEL_UNDO } from "./apiPaths";

const getUrl = (clientId: string): string => {
  return ACCOUNT_API_CONTRACT_CANCEL_UNDO.replace("<client_id>", clientId);
};

export type UndoCancelContractErrorResponse = {
  error?:
    | "service_not_found"
    | "plan_not_found"
    | "data_out_of_scope"
    | "nkdk_inactive"
    | undefined;
};

export const postUndoCancelContract = (
  clientId: string,
): Promise<AxiosResponse<void>> => {
  return axios.post(
    getUrl(clientId),
    {},
    {
      withCredentials: true,
    },
  );
};
