import * as React from "react";
import { useImperativeHandle } from "react";

import { Box, Checkbox, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import RequiredTag from "../../Tag/RequiredTag";

export type InterestFieldValues = {
  interest1: boolean;
  interest2: boolean;
  interest3: boolean;
  interest4: boolean;
  interest5: boolean;
  interest6: boolean;
  interest7: boolean;
  interest8: boolean;
  interest9: boolean;
  interest10: boolean;
  interest11: boolean;
  interest12: boolean;
  interest13: boolean;
};

type HTMLInterestFieldElement = {
  name: string;
  onChange?: (event: {
    target: { name: string; value: InterestFieldValues };
  }) => void;
  value?: InterestFieldValues;
};

type Props = {
  testId?: string;
} & HTMLInterestFieldElement;

export const InterestField = React.forwardRef<HTMLInterestFieldElement, Props>(
  ({ testId = "interest", name, onChange }, ref) => {
    const { t } = useTranslation();

    const interest1Ref = React.useRef<HTMLInputElement>(null);
    const interest2Ref = React.useRef<HTMLInputElement>(null);
    const interest3Ref = React.useRef<HTMLInputElement>(null);
    const interest4Ref = React.useRef<HTMLInputElement>(null);
    const interest5Ref = React.useRef<HTMLInputElement>(null);
    const interest6Ref = React.useRef<HTMLInputElement>(null);
    const interest7Ref = React.useRef<HTMLInputElement>(null);
    const interest8Ref = React.useRef<HTMLInputElement>(null);
    const interest9Ref = React.useRef<HTMLInputElement>(null);
    const interest10Ref = React.useRef<HTMLInputElement>(null);
    const interest11Ref = React.useRef<HTMLInputElement>(null);
    const interest12Ref = React.useRef<HTMLInputElement>(null);
    const interest13Ref = React.useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          set value(x: InterestFieldValues) {
            if (interest1Ref.current)
              interest1Ref.current.checked = x.interest1;
            if (interest2Ref.current)
              interest2Ref.current.checked = x.interest2;
            if (interest3Ref.current)
              interest3Ref.current.checked = x.interest3;
            if (interest4Ref.current)
              interest4Ref.current.checked = x.interest4;
            if (interest5Ref.current)
              interest5Ref.current.checked = x.interest5;
            if (interest6Ref.current)
              interest6Ref.current.checked = x.interest6;
            if (interest7Ref.current)
              interest7Ref.current.checked = x.interest7;
            if (interest8Ref.current)
              interest8Ref.current.checked = x.interest8;
            if (interest9Ref.current)
              interest9Ref.current.checked = x.interest9;
            if (interest10Ref.current)
              interest10Ref.current.checked = x.interest10;
            if (interest11Ref.current)
              interest11Ref.current.checked = x.interest11;
            if (interest12Ref.current)
              interest12Ref.current.checked = x.interest12;
            if (interest13Ref.current)
              interest13Ref.current.checked = x.interest13;
          },
          name: name,
        } as HTMLInterestFieldElement;
      },
      [
        interest1Ref,
        interest2Ref,
        interest3Ref,
        interest4Ref,
        interest5Ref,
        interest6Ref,
        interest7Ref,
        interest8Ref,
        interest9Ref,
        interest10Ref,
        interest11Ref,
        interest12Ref,
        interest13Ref,
      ],
    );

    const onChangeElement = () => {
      if (onChange) {
        const value: InterestFieldValues = {
          interest1: interest1Ref.current?.checked || false,
          interest2: interest2Ref.current?.checked || false,
          interest3: interest3Ref.current?.checked || false,
          interest4: interest4Ref.current?.checked || false,
          interest5: interest5Ref.current?.checked || false,
          interest6: interest6Ref.current?.checked || false,
          interest7: interest7Ref.current?.checked || false,
          interest8: interest8Ref.current?.checked || false,
          interest9: interest9Ref.current?.checked || false,
          interest10: interest10Ref.current?.checked || false,
          interest11: interest11Ref.current?.checked || false,
          interest12: interest12Ref.current?.checked || false,
          interest13: interest13Ref.current?.checked || false,
        };
        onChange({ target: { name, value } });
      }
    };

    return (
      <>
        <Typography as="legend" bold className="mb-2">
          {t("attributes.interest")} <RequiredTag on={false} />
        </Typography>
        <Box>
          <Checkbox
            data-testid={`checkbox-${testId}-interest1`}
            label={t("attribute_values.interest.1")}
            ref={interest1Ref}
            id="interest1"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest2`}
            label={t("attribute_values.interest.2")}
            ref={interest2Ref}
            id="interest2"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest3`}
            label={t("attribute_values.interest.3")}
            ref={interest3Ref}
            id="interest3"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest4`}
            label={t("attribute_values.interest.4")}
            ref={interest4Ref}
            id="interest4"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest5`}
            label={t("attribute_values.interest.5")}
            ref={interest5Ref}
            id="interest5"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest6`}
            label={t("attribute_values.interest.6")}
            ref={interest6Ref}
            id="interest6"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest7`}
            label={t("attribute_values.interest.7")}
            ref={interest7Ref}
            id="interest7"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest8`}
            label={t("attribute_values.interest.8")}
            ref={interest8Ref}
            id="interest8"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest9`}
            label={t("attribute_values.interest.9")}
            ref={interest9Ref}
            id="interest9"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest10`}
            label={t("attribute_values.interest.10")}
            ref={interest10Ref}
            id="interest10"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest11`}
            label={t("attribute_values.interest.11")}
            ref={interest11Ref}
            id="interest11"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest12`}
            label={t("attribute_values.interest.12")}
            ref={interest12Ref}
            id="interest12"
            onChange={onChangeElement}
          />
          <Checkbox
            data-testid={`checkbox-${testId}-interest13`}
            label={t("attribute_values.interest.13")}
            ref={interest13Ref}
            id="interest13"
            onChange={onChangeElement}
          />
        </Box>
      </>
    );
  },
);

InterestField.displayName = "Nid.Form.InterestField";
