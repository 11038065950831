import clsx from "clsx";
import type React from "react";
import styles from "./AccountCircleIcon.module.css";

export const AccountCircleIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => {
  const { className, ...others } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      aria-label="Icon of Account Circle"
      aria-hidden="true"
      className={clsx(styles.blue90, className)}
      {...others}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4998 5.21256C22.7593 5.21256 27.8333 10.2865 27.8333 16.5459C27.8333 19.2859 26.861 21.7988 25.2426 23.7584C23.2175 20.9764 19.9389 19.27 16.3608 19.27C12.8601 19.27 9.64636 20.9033 7.61249 23.5793C6.08112 21.6468 5.16667 19.2032 5.16667 16.5459C5.16667 10.2865 10.2406 5.21256 16.4998 5.21256ZM9.01185 25.0534C11.0086 26.8123 13.6296 27.8792 16.4998 27.8792C19.2873 27.8792 21.8397 26.873 23.8136 25.204C22.1808 22.7756 19.4063 21.27 16.3608 21.27C13.3788 21.27 10.6568 22.7134 9.01185 25.0534ZM16.4998 30.5459C24.2321 30.5459 30.5 24.2781 30.5 16.5459C30.5 8.81373 24.2321 2.5459 16.4998 2.5459C8.76786 2.5459 2.5 8.81379 2.5 16.5459C2.5 24.278 8.76786 30.5459 16.4998 30.5459ZM19.4901 13.3686C19.4901 11.7905 18.2111 10.5114 16.6332 10.5114C15.055 10.5114 13.7759 11.7905 13.7759 13.3686C13.7759 14.9466 15.055 16.2257 16.6332 16.2257C18.2111 16.2257 19.4901 14.9466 19.4901 13.3686ZM21.4901 13.3686C21.4901 16.0511 19.3157 18.2257 16.6332 18.2257C13.9504 18.2257 11.7759 16.0512 11.7759 13.3686C11.7759 10.6859 13.9504 8.51142 16.6332 8.51142C19.3157 8.51142 21.4901 10.686 21.4901 13.3686Z"
      />
    </svg>
  );
};
