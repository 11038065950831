import { path } from "@/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSecurityPasskeyProvider } from "../SecurityPasskeyProvider";

import { aaguidInfoFn, convertToAtDateTime } from "../formatPasskeyItem";

export const useSecurityPasskeyCreatedFeature = () => {
  const navigate = useNavigate();
  const { passkeyData } = useSecurityPasskeyProvider();

  useEffect(() => {
    if (!passkeyData || passkeyData.id === "") {
      navigate(path.security.root);
    }
  }, [passkeyData, navigate]);

  if (!passkeyData) {
    return {
      status: "loading",
    } as const;
  }

  const { aaguid, createdAt } = passkeyData;
  const aaguidInfo = aaguidInfoFn(aaguid);

  return {
    status: "ok",
    title: aaguidInfo?.name,
    createdAt: convertToAtDateTime(createdAt),
    backupEligible: passkeyData.backupEligible,
  } as const;
};
