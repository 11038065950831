import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";

import {
  type PersonalJobInfoFormValues,
  buildUserInfoPatch,
} from "@/features/Personal/JobInfo/PersonalJobInfoFormProvider";
import {
  type PatchUserInfoError,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { useUserInfo } from "@/hooks/useUserInfo";
import { path } from "@/routes";
import { type JobInfoMaster, useJobInfoMaster } from "nid-common";

export const requireDetail = (
  occupationNo: JobInfoMaster["occupationNo"],
  formOccupationNo: string,
) => {
  return occupationNo.find((value) => value.code === formOccupationNo)
    ?.requireDetail;
};

const buildSelectOptions = (data: { name: string; code: string }[]) => {
  return data.map((v) => {
    return { label: v.name, value: v.code };
  });
};

export const usePersonalJobInfoInputFeature = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<PersonalJobInfoFormValues>();

  const useUserInfoReturn = useUserInfo();
  const useJobInfoMasterReturn = useJobInfoMaster();
  const navigate = useNavigate();
  const { patch } = usePatchUserInfo();
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string>();
  useEffect(() => {
    if (
      useUserInfoReturn.status === "error" ||
      useJobInfoMasterReturn.status === "error"
    ) {
      navigate(path.error.root, { replace: true });
    }
  }, [useUserInfoReturn.status, useJobInfoMasterReturn.status]);

  if (
    useJobInfoMasterReturn.status !== "ok" ||
    useUserInfoReturn.status !== "ok"
  ) {
    return { status: "loading" } as const;
  }

  const getOptions = (jobInfo: JobInfoMaster) => {
    return {
      occupationNo: [
        {
          label: t("personal.edit.job_info.text.placeholder.occupationNo"),
          value: "0",
        },
        ...buildSelectOptions(jobInfo.occupationNo),
      ],
      businessNo: [
        {
          label: t("personal.edit.job_info.text.placeholder.businessNo"),
          value: "0",
        },
        ...buildSelectOptions(jobInfo.businessNo),
      ],
      jobNo: [
        {
          label: t("personal.edit.job_info.text.placeholder.jobNo"),
          value: "0",
        },
        ...buildSelectOptions(jobInfo.jobNo),
      ],
      positionNo: [
        {
          label: t("personal.edit.job_info.text.placeholder.positionNo"),
          value: "0",
        },
        ...buildSelectOptions(jobInfo.positionNo),
      ],
      employeesNo: [
        {
          label: t("personal.edit.job_info.text.placeholder.employeesNo"),
          value: "0",
        },
        ...buildSelectOptions(jobInfo.employeesNo),
      ],
      incomeNo: [
        {
          label: t("personal.edit.job_info.text.placeholder.incomeNo"),
          value: "0",
        },
        ...buildSelectOptions(jobInfo.incomeNo),
      ],
    };
  };

  const handlePatchUserInfo: SubmitHandler<PersonalJobInfoFormValues> = async (
    input: PersonalJobInfoFormValues,
  ): Promise<void> => {
    try {
      await patch(
        buildUserInfoPatch(input, useJobInfoMasterReturn.occupationNo),
        true,
      );
      setValue("inputDone", true);
      navigate(path.personal.jobInfo.confirm);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        navigate(path.error.root);
      }
    }
  };

  const formValue = watch();
  const requireOccupation =
    useUserInfoReturn?.userInfo?.occupation !== undefined;
  const requireJobDetail = (occupationNo: string) => {
    return (
      useJobInfoMasterReturn.occupationNo.find(
        (item) => item.code === occupationNo,
      )?.requireDetail ?? false
    );
  };
  const registers = {
    occupationNo: register("jobInfo.occupationNo", {
      validate: (value: string) => {
        if (requireOccupation && value === "0") {
          return t("personal.edit.job_info.errors.occupationNo.required");
        }
        return undefined;
      },
      onChange: async () => {
        if (!requireJobDetail(formValue.jobInfo.occupationNo)) {
          const fieldsToClear: (keyof PersonalJobInfoFormValues["jobInfo"])[] =
            ["businessNo", "jobNo", "positionNo", "employeesNo"];

          for (const field of fieldsToClear) {
            setValue(`jobInfo.${field}`, "0");
            await trigger(`jobInfo.${field}` as const);
          }
        }
      },
    }),
    businessNo: register("jobInfo.businessNo", {
      validate: (value: string) => {
        if (requireJobDetail(formValue.jobInfo.occupationNo) && value === "0") {
          return t("personal.edit.job_info.errors.detail.required");
        }
        return undefined;
      },
    }),
    jobNo: register("jobInfo.jobNo", {
      validate: (value: string) => {
        if (requireJobDetail(formValue.jobInfo.occupationNo) && value === "0") {
          return t("personal.edit.job_info.errors.detail.required");
        }
        return undefined;
      },
    }),
    positionNo: register("jobInfo.positionNo", {
      validate: (value: string) => {
        if (requireJobDetail(formValue.jobInfo.occupationNo) && value === "0") {
          return t("personal.edit.job_info.errors.detail.required");
        }
        return undefined;
      },
    }),
    employeesNo: register("jobInfo.employeesNo", {
      validate: (value: string) => {
        if (requireJobDetail(formValue.jobInfo.occupationNo) && value === "0") {
          return t("personal.edit.job_info.errors.detail.required");
        }
        return undefined;
      },
    }),
    incomeNo: register("incomeNo"),
  };

  const retErrors = {
    ...errors,
    ...(apiError ? { api: { message: apiError } } : undefined),
  };

  return {
    status: "ok",
    jobInfo: useJobInfoMasterReturn,
    options: getOptions(useJobInfoMasterReturn),
    registers,
    handleSubmit: handleSubmit(handlePatchUserInfo),
    requireOccupation,
    requireJobDetail: requireJobDetail(formValue.jobInfo.occupationNo),
    errors: retErrors,
    buttonEnabled: Object.keys(retErrors).length === 0,
  } as const;
};
