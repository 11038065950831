import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import { PaymentHistoriesFeature } from "@/features/Payments/Histories/PaymentHistoriesFeature";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";
import { Link, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

export const PaymentHistoriesPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.payment_histories")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.payments.root} size="14">
            {t("breadcrumbs.payments")}
          </Link>
          <Typography as="span" size="14" data-testid="breadcrumb-last-item">
            {t("breadcrumbs.payment_histories")}
          </Typography>
        </>
      }
      description={t("payments.top.description")}
    >
      <LoginWallProvider maxAge={1800}>
        <ArticleTitle
          title={t("payments.histories.title")}
          testId="article-payments-histories"
        >
          <Typography as="p" className="nid-article-description">
            {t("payments.histories.description")}
          </Typography>
        </ArticleTitle>
        <PaymentHistoriesFeature />
      </LoginWallProvider>
    </Layout>
  );
};
