import * as React from "react";

import { Box, Radio, Typography } from "nikkei-ui";

import { useUserInfoLabel } from "@/hooks/useUserInfoLabel";
import { useTranslation } from "react-i18next";
import RequiredTag from "../../Tag/RequiredTag";

const DISPLAY_NAME = "Nid.Form.SexField";

type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type SexFieldProps = {
  testId?: string;
  errorMessage?: string;
} & InputWithRefProps;

const SexField = React.forwardRef<HTMLInputElement, SexFieldProps>(
  ({ testId = "sex", ...props }, ref) => {
    const { t } = useTranslation();
    const { getSexLabel } = useUserInfoLabel();
    return (
      <>
        <Typography as="legend" bold className="mb-2">
          {t("attributes.sex")}
          <RequiredTag on={false} />
        </Typography>
        <Box className="mb-1">
          <Radio
            data-testid={`radio-${testId}-male`}
            {...props}
            label={getSexLabel("1")}
            value="1"
            ref={ref}
          />
          <Radio
            data-testid={`radio-${testId}-female`}
            {...props}
            label={getSexLabel("2")}
            value="2"
            ref={ref}
          />
          <Radio
            data-testid={`radio-${testId}-other`}
            {...props}
            label={getSexLabel("3")}
            value="3"
            ref={ref}
          />
          <Radio
            data-testid={`radio-${testId}-no-answer`}
            {...props}
            label={getSexLabel("4")}
            value="4"
            ref={ref}
          />
        </Box>
      </>
    );
  },
);

SexField.displayName = DISPLAY_NAME;
const Root = SexField;
export { Root, SexField };
export type { SexFieldProps };
export default SexField;
