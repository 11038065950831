import type React from "react";

import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import {
  type PaymentsCard,
  type PaymentsService,
  mapToPaymentsCard,
  usePaymentCards,
} from "@/hooks/usePaymentCards";
import { path } from "@/routes";
import { getPaymentCards } from "nid-common/api/account";
import { Box, LinkButton } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./PaymentsAlertFeature.module.css";

const Services: React.FC<{ services: PaymentsService[] }> = ({ services }) => {
  if (services.length === 0) {
    return null;
  }
  return (
    <Box className="my-2">
      {services.map((service) => (
        <Box
          className={styles.serviceItem}
          key={service.serviceId}
          data-testid="service-name"
        >
          {service.name} {service.planId ? `（${service.planName}）` : ""}
        </Box>
      ))}
    </Box>
  );
};

export const CardLinks: React.FC<{ cards: PaymentsCard[] }> = ({ cards }) => {
  const { t } = useTranslation();
  if (cards.length === 0) {
    return null;
  }
  return (
    <Box className="mt-4">
      {cards.map((card) => (
        <LinkButton
          to={path.payments.update.card(card.id)}
          variant="border"
          color="red"
          data-testid="button-update"
          className={styles.alertButton}
          key={card.id}
        >
          {t("payments.top.button.alertUpdate", { last4: card.last4Number })}
        </LinkButton>
      ))}
    </Box>
  );
};

const AlertComponent: React.FC<{
  cards: PaymentsCard[];
  unpaidServices: PaymentsService[];
  titleKey:
    | "payments.top.title.unpaidServices"
    | "payments.top.title.invalidCard";
  textKey: "payments.top.text.unpaidServices" | "payments.top.text.invalidCard";
  hasInner?: boolean;
}> = ({ cards, unpaidServices, titleKey, textKey, hasInner = false }) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.alertContainer}>
      <Box className={hasInner ? "nid-section-inner" : ""}>
        <Box data-testid="payments-alert">
          <AlertMessage testId="alert" title={t(titleKey)}>
            {t(textKey)}
            <Services services={unpaidServices} />
            <CardLinks cards={cards} />
          </AlertMessage>
        </Box>
      </Box>
    </Box>
  );
};

export const PaymentsAlertFeature: React.FC = () => {
  const { cards } = usePaymentCards();
  const invalidCards = cards.filter((card) => {
    return card.services.length > 0 && card.status === "invalid";
  });
  const unpaidCards = cards.filter((card) => card.status === "unpaid");
  const unpaidServices = cards.flatMap((card) =>
    card.services.filter((service) => service.unpaid),
  );

  if (invalidCards.length === 0 && unpaidCards.length === 0) {
    return null;
  }

  return (
    <AlertComponent
      cards={unpaidCards.length > 0 ? unpaidCards : invalidCards}
      unpaidServices={unpaidCards.length > 0 ? unpaidServices : []}
      titleKey={
        unpaidCards.length > 0
          ? "payments.top.title.unpaidServices"
          : "payments.top.title.invalidCard"
      }
      textKey={
        unpaidCards.length > 0
          ? "payments.top.text.unpaidServices"
          : "payments.top.text.invalidCard"
      }
      hasInner={true}
    />
  );
};

export const PaymentsUnpaidAlertFeature: React.FC = () => {
  const payments = useSWR("/account/payments/cards", () =>
    getPaymentCards({}),
  ).data;

  if (!payments?.data) {
    return null;
  }

  const cards = payments.data.cards.map(mapToPaymentsCard);
  const unpaidCards = cards.filter((card) => card.status === "unpaid");
  const unpaidServices = cards.flatMap((card) =>
    card.services.filter((service) => service.unpaid),
  );

  if (unpaidCards.length === 0 && unpaidServices.length === 0) {
    return null;
  }

  return (
    <AlertComponent
      cards={unpaidCards}
      unpaidServices={unpaidServices}
      titleKey="payments.top.title.unpaidServices"
      textKey="payments.top.text.unpaidServices"
      hasInner={false}
    />
  );
};
