import { useEffect } from "react";

import { useNavigate } from "react-router";

import { useAuthenticatorMfaSetupToken } from "@/features/Security/Mfa/Authenticator/AuthenticatorMfaSetupTokenProvider";
import { path } from "@/routes";
import { postMfaSetupAuthenticatorStart } from "nid-common/api/account";

export const useSecurityMfaAuthenticatorFeature = () => {
  const { value, setValue } = useAuthenticatorMfaSetupToken();
  const navigate = useNavigate();

  useEffect(() => {
    postMfaSetupAuthenticatorStart()
      .then((response) => {
        setValue({
          uri: response.data.uri,
          authenticator_secret: response.data.authenticator_secret,
          mfa_setup_token: response.data.mfa_setup_token,
        });
      })
      .catch(() => {
        navigate(path.security.mfa.root, { replace: true });
      });
  }, []);

  return { token: value };
};
