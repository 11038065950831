import type * as React from "react";

import { Row } from "@/components/ui/Row";
import RequiredTag from "@/components/ui/Tag/RequiredTag";
import {
  Box,
  FormControl,
  InfoCircleFillIcon,
  InputField,
  Link,
  type OptionItem,
  Select,
  Textarea,
  Typography,
  type UseFormRegisterReturn,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

const DISPLAY_NAME = "Nid.Form.DomesticAddressField";

type DomesticAddressFieldProps = {
  refreshAddress: () => void;
  address: OptionItem[];
  errors?: {
    zipCode?: { message?: string };
    addressCode?: { message?: string; type: string };
    address1?: { message?: string; type: string };
    address2?: { message?: string; type: string };
    tel?: { message?: string };
  };
  errorDetails?: React.ReactNode;
  registers?: {
    zipCode?: UseFormRegisterReturn<string>;
    addressCode?: UseFormRegisterReturn<string>;
    address1?: UseFormRegisterReturn<string>;
    address2?: UseFormRegisterReturn<string>;
    tel?: UseFormRegisterReturn<string>;
  };
  zipCodeRequired: boolean | undefined;
};

const searchZipCodeURL = import.meta.env.VITE_URL_SEARCH_ZIP_CODE;

const DomesticAddressField: React.FC<DomesticAddressFieldProps> = ({
  errors,
  errorDetails,
  ...props
}) => {
  const { t } = useTranslation();
  const placeholder = t("personal.edit.address.placeholder.domestic", {
    returnObjects: true,
  });
  const label = t("personal.edit.address.label.domestic", {
    returnObjects: true,
  });
  const text = t("personal.edit.address.text.domestic", {
    returnObjects: true,
  });

  const addressOptions =
    props.address.length === 0
      ? [{ label: "郵便番号を入力してください", value: "0" }]
      : props.address;

  const addressError = (
    errors?.address1 ||
    errors?.address2 ||
    errors?.addressCode
  )?.message;

  return (
    <>
      <fieldset className="nid-account-edit-fieldset">
        <Typography
          as="legend"
          lineHeight="135"
          bold
          className="mb-1"
          color={errors?.zipCode ? "red90" : undefined}
        >
          <Box className="nid-item-center" />
          <label htmlFor="input-zip-code">{label.zip_code}</label>
          <RequiredTag on={props.zipCodeRequired} />
        </Typography>
        <Row className="mb-1">
          <Box className="nid-form-postcode">
            <InputField
              placeholder={placeholder.zip_code}
              aria-label={placeholder.zip_code}
              data-testid="input-zip-code"
              id="input-zip-code"
              autoComplete="postal-code"
              status={errors?.zipCode ? "error" : undefined}
              maxLength={7}
              {...props.registers?.zipCode}
            />
          </Box>
          <Box className="nid-form-search-address">
            <InfoCircleFillIcon />
            <Link
              href={searchZipCodeURL}
              newTab
              data-testid="link-search-address"
            >
              <Typography size="14" color="link" className="ml-1">
                {text.search_zip_code}
              </Typography>
            </Link>
          </Box>
        </Row>
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.zipCode ? "error" : undefined}
          data-testid="error-zip-code"
        >
          {errors?.zipCode?.message}
        </FormControl.Validation>
      </fieldset>

      <Box className="mb-1">
        <Typography
          as="legend"
          lineHeight="135"
          bold
          className="mb-2"
          color={errors?.zipCode ? "red90" : undefined}
        >
          <label htmlFor="select-address-code">{label.address_head}</label>
          <RequiredTag on={false} />
        </Typography>

        <fieldset className="nid-account-edit-fieldset">
          <Typography size="14" className="mb-2">
            <label htmlFor="select-address-code">{label.address_code}</label>
          </Typography>
          <Row className="nid-input-control-head">
            <Select
              name="address-code"
              disabled={props.address.length <= 1}
              containerclassname="w-full"
              options={addressOptions}
              defaultValue="0"
              id="select-address-code"
              data-testid="select-address-code"
              status={errors?.addressCode ? "error" : undefined}
              {...props.registers?.addressCode}
            />
          </Row>
        </fieldset>

        <fieldset className="nid-account-edit-fieldset">
          <Typography size="14">
            <label htmlFor="input-address1">{label.address1}</label>
          </Typography>
          <Row className="nid-input-control-head">
            <InputField
              maxLength={250}
              id="input-address1"
              data-testid="input-address1"
              placeholder={placeholder.address1}
              aria-label={placeholder.address1}
              autoComplete="address-line1"
              status={errors?.address1 ? "error" : undefined}
              {...props.registers?.address1}
            />
          </Row>
        </fieldset>

        <fieldset className="nid-account-edit-fieldset">
          <Typography size="14" className="mb-2">
            <label htmlFor="input-address2">{label.address2}</label>
          </Typography>
          <Row className="nid-input-control-head mb-1">
            <Textarea
              maxLength={100}
              labelPosition="horizontal"
              className="w-full"
              id="input-address2"
              data-testid="input-address2"
              placeholder={placeholder.address2}
              aria-label={placeholder.address2}
              autoComplete="address-line2"
              status={errors?.address2 ? "error" : undefined}
              {...props.registers?.address2}
            />
          </Row>
          <FormControl.Validation
            className="nid-error-field"
            status={addressError ? "error" : undefined}
            data-testid="error-address"
          >
            {addressError}
          </FormControl.Validation>
          {errorDetails}
        </fieldset>
      </Box>

      <fieldset className="nid-account-edit-fieldset">
        <Typography
          as="legend"
          lineHeight="135"
          bold
          color={errors?.zipCode ? "red90" : undefined}
          className="mb-1"
        >
          <label htmlFor="input-tel">{label.tel}</label>
          <RequiredTag on={false} />
        </Typography>
        <Row className="nid-input-control-head">
          <InputField
            max-length={60}
            id="input-tel"
            data-testid="input-tel"
            placeholder={placeholder.tel}
            aria-label={placeholder.tel}
            autoComplete="tel"
            status={errors?.tel ? "error" : undefined}
            {...props.registers?.tel}
          />
        </Row>
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.tel ? "error" : undefined}
          data-testid="error-tel"
        >
          {errors?.tel?.message}
        </FormControl.Validation>
      </fieldset>
    </>
  );
};

DomesticAddressField.displayName = DISPLAY_NAME;
const Root = DomesticAddressField;
export { Root, DomesticAddressField };
export type { DomesticAddressFieldProps };
export default DomesticAddressField;
