import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import { Box } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useContractCancel } from "./hooks/useContractCancel";

export const ServiceContractCancelAlertFeature: React.FC = () => {
  const { t } = useTranslation();
  const { cancelCheck, contract, status } = useContractCancel();

  const alert = (() => {
    if (status === "loading" || status === "contract_not_found") {
      return { type: "none" } as const;
    }
    const detail = contract.contractDetail;
    if (!detail.isPaidPlan && detail.isTrial) {
      return { type: "trial" } as const;
    }
    if (cancelCheck.status === "ok" && cancelCheck.result?.penalty) {
      return { type: "penalty", data: cancelCheck.result.penalty } as const;
    }
    return { type: "none" } as const;
  })();

  switch (alert.type) {
    case "trial":
      return (
        <Box className="nid-section-inner mb-4">
          <AlertMessage
            testId="cancel-penalty-alert"
            title={t("services.service_contract_cancel.note.label")}
          >
            {t(
              "services.service_contract_cancel.note.free_trial_cancel_caution",
            )}
          </AlertMessage>
        </Box>
      );
    case "penalty":
      if (alert.data.amount === 0) {
        return null;
      }
      return (
        <Box className="nid-section-inner mb-4">
          <AlertMessage
            testId="cancel-penalty-alert"
            title={t("services.service_contract_cancel.note.label")}
          >
            {t("services.service_contract_cancel.note.penalty", {
              penaltyAmount: alert.data.amount.toLocaleString("ja-JP"),
            })}
          </AlertMessage>
        </Box>
      );
    case "none":
      return null;
  }
};
