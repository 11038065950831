import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";

import { SecurityPasswordChangeInputFeature } from "@/features/Security/Password/Change/SecurityPasswordChangeInputFeature";
import { LoginWallProvider } from "nid-common";
import { Typography } from "nikkei-ui";

export const SecurityPasswordChangeInputPage = (): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <LoginWallProvider maxAge={600}>
        <ArticleTitle
          testId="article-security-password-input"
          title={t("security.edit.password_change.input.title")}
        >
          <Typography as="p" className="nid-article-description">
            {t("security.edit.password_change.input.description")}
          </Typography>
        </ArticleTitle>
        <SecurityPasswordChangeInputFeature />
      </LoginWallProvider>
    </>
  );
};

export default SecurityPasswordChangeInputPage;
