import type React from "react";

import { Typography } from "nikkei-ui";

import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { replace, useLoaderData } from "react-router";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { ServiceContractCancelAlertFeature } from "@/features/Services/Contract/Cancel/ServiceContractAlertFeature";
import { ServiceContractCancelConfirmFeature } from "@/features/Services/Contract/Cancel/ServiceContractCancelConfirmFeature";
import { useContractDetail } from "@/hooks/useContractDetail";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { getRegisteredRelyingParty } from "nid-common/api/account";

/**
 * loader
 * react-routerのloaderを使う
 * getRegisteredRelyingPartyを取得してエラーの場合redirect
 * @returns Promise<{clientId: string}>
 */
export const loader = async ({ params }: { params: { clientId?: string } }) => {
  if (!params.clientId) {
    return replace(path.services.root);
  }

  try {
    const result = await getRegisteredRelyingParty(params.clientId);
    return { clientId: params.clientId, relyingParty: result.data };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return replace(path.services.root);
    }
    return replace(path.error.root);
  }
};

export const ServiceContractCancelConfirmPage: React.FC = () => {
  const { t } = useTranslation();
  const { clientId: serviceId } = useLoaderData() as { clientId: string };
  const { status, contractDetail } = useContractDetail(serviceId);

  return (
    <>
      <ServiceContractCancelAlertFeature />
      <ArticleTitle
        title={t("services.service_contract_cancel_confirm.title")}
        testId="article-service-contract-cancel-confirm"
      >
        {status === "ok" ? (
          <Typography as="p" className="nid-article-description">
            {contractDetail.isPaidPlan
              ? t(
                  "services.service_contract_cancel_confirm.description.paid_plan",
                )
              : t(
                  "services.service_contract_cancel_confirm.description.free_plan",
                )}
          </Typography>
        ) : (
          <ContentSkeletonLoader />
        )}
      </ArticleTitle>
      <ServiceContractCancelConfirmFeature />
    </>
  );
};

export default ServiceContractCancelConfirmPage;
