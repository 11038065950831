import { PasskeyHero } from "@/components/ui/Passkeys/PasskeyHero";
import { path } from "@/routes";
import { usePasskeys } from "nid-common";

export const AccountTopPasskeyHeroFeature: React.FC = () => {
  const r = usePasskeys();

  if (r.status !== "ok") {
    return null;
  }

  if (r.data.passkeys.length !== 0) {
    return null;
  }

  return (
    <PasskeyHero
      link={path.security.passkeys.root}
      testId="security-item-passkey-empty"
      enableCloseButton={true}
    />
  );
};
