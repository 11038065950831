import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import { PaymentHistoryDetailFeature } from "@/features/Payments/Histories/PaymentHistoryDetailFeature";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";
import { Box, Link, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

export const PaymentHistoryDetailPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.payment_detail")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.payments.root} size="14">
            {t("breadcrumbs.payments")}
          </Link>
          <Link to={path.payments.histories.root} size="14">
            {t("breadcrumbs.payment_histories")}
          </Link>
          <Typography as="span" size="14" data-testid="breadcrumb-last-item">
            {t("breadcrumbs.payment_detail")}
          </Typography>
        </>
      }
      description={t("payments.top.description")}
    >
      <LoginWallProvider maxAge={1800}>
        <Box className="print-page">
          <Box className="print-section">
            <ArticleTitle
              title={t("payments.histories.detail.title")}
              testId="article-payments-history-detail"
            />
          </Box>
          <PaymentHistoryDetailFeature />
        </Box>
      </LoginWallProvider>
    </Layout>
  );
};
