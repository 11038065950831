import { path } from "@/routes";
import { isAxiosError } from "axios";
import { postContractCancel } from "nid-common/api/account";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AtlasTracking } from "../../../../../../../../packages/nid-common";
import { useContractCancel } from "./useContractCancel";

export const useContractCancelConfirmFeature = () => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const { status, clientId, planId, contract, cancelCheck, cancelSurvey } =
    useContractCancel();

  useEffect(() => {
    if (status === "contract_not_found") {
      navigate(path.services.root);
      return;
    }
    if (status !== "ok") return;
    if (cancelCheck.result?.cancelable === false) {
      navigate(path.services.contract.root(clientId), {
        replace: true,
      });
      return;
    }
    if (!cancelCheck.result) {
      navigate(path.services.contract.root(clientId), {
        replace: true,
      });
      return;
    }
  }, [cancelCheck, cancelSurvey, clientId, status]);

  if (
    status === "loading" ||
    status === "contract_not_found" ||
    !cancelCheck.result ||
    !cancelSurvey.result ||
    cancelCheck.result?.cancelable === false
  ) {
    return { status: "loading" } as const;
  }

  const submit = async () => {
    if (submitting || !cancelSurvey.result || !cancelCheck.result) {
      return;
    }
    try {
      setSubmitting(true);
      const getValue = (index: number): "0" | "1" | undefined => {
        if (cancelSurvey.choices.at(index)?.label === undefined) {
          return undefined;
        }
        return cancelSurvey.result.choices[index] ? "1" : "0";
      };

      const body = {
        plan_id: planId,
        answer1: getValue(0),
        answer2: getValue(1),
        answer3: getValue(2),
        answer4: getValue(3),
        answer5: getValue(4),
        answer6: getValue(5),
        answer7: getValue(6),
        answer8: getValue(7),
        answer9: getValue(8),
        answer10: getValue(9),
        answer_text: !cancelSurvey.hasText
          ? undefined
          : cancelSurvey.result.text,
      };
      await postContractCancel(clientId, body);
      navigate(path.services.contract.cancel.complete(clientId));
    } catch (e) {
      if (
        isAxiosError(e) &&
        e.response?.status === 503 &&
        e.response?.data.error === "nkdk_inactive"
      ) {
        navigate(path.services.contract.maintenance(clientId || ""), {
          replace: true,
        });
      } else {
        AtlasTracking.unknownError(e);
        navigate(path.error.root, { replace: true });
      }
    } finally {
      await contract.mutate(undefined);
      setSubmitting(false);
    }
  };

  return {
    status: "ok",
    submit,
    submitting,
    contract: {
      clientId: clientId,
      isPaid: contract.contractDetail.isPaidPlan,
      isFreeTrial: contract.contractDetail.isFreeTrial,
      serviceName: cancelCheck.result.service.name,
      planName: cancelCheck.result.plan.name,
      expiration: cancelCheck.result.expiration,
    },
    survey: cancelSurvey.hasSurvey
      ? {
          displayChoices: cancelSurvey.result.choices
            .map((c, index) => {
              return c ? cancelSurvey.choices[index].label : undefined;
            })
            .filter((v): v is string => v !== undefined),
          text: cancelSurvey.result.text,
        }
      : undefined,
  } as const;
};
