import { useContext, useEffect } from "react";

import { Box, LinkButton, Typography } from "nikkei-ui";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalEmailChangeContext } from "@/features/Personal/EmailChange/PersonalEmailChangeFormProvider";
import { path } from "@/routes";

import styles from "./complete.module.css";

const PersonalEmailChangeCompletePage = (): React.JSX.Element => {
  const { t } = useTranslation();
  const [email, _] = useContext(PersonalEmailChangeContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!email) navigate(path.personal.root, { replace: true });
  }, [email]);

  if (!email) return <></>;

  return (
    <Box className="nid-article-header mb-10">
      <Box className="mb-20">
        <ArticleTitle
          testId="article-personal-email-change-complete"
          title={t("personal.edit.email_change.complete.title")}
        >
          <Typography
            as="p"
            className={`nid-article-description ${styles.email}`}
          >
            <Trans
              t={t}
              i18nKey="personal.edit.email_change.complete.description"
              values={{ email }}
              shouldUnescape={true}
              components={[
                <Typography
                  key="email"
                  as="span"
                  bold
                  data-dd-privacy="mask"
                />,
              ]}
            />
          </Typography>
        </ArticleTitle>
      </Box>

      <Box className="nid-article-header-inner">
        <LinkButton to={path.personal.root} data-testid="submit" size="full">
          {t("personal.edit.button.complete")}
        </LinkButton>
      </Box>
    </Box>
  );
};

export default PersonalEmailChangeCompletePage;
