import { Box, Link, Typography } from "nikkei-ui";

export type BackButtonProps = {
  to?: string;
  buttonText: string;
  testId?: string;
  onClick?: () => void;
};

export const BackButton = ({
  to,
  buttonText,
  testId = "button-back",
  onClick,
}: BackButtonProps) => {
  return (
    <Box className="nid-button-back">
      <Link to={to} onClick={onClick} data-testid={testId}>
        <Typography color="link">{buttonText}</Typography>
      </Link>
    </Box>
  );
};
