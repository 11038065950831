import clsx from "clsx";
import type React from "react";
import styles from "./EmailIcon.module.css";

export const EmailIcon: React.FC<React.ComponentProps<"svg">> = (props) => {
  const { className, ...others } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      aria-label="Icon of Email"
      aria-hidden="true"
      className={clsx(className, styles.blue90)}
      {...others}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8331 5.87927C28.9369 5.87927 29.8334 6.76841 29.8334 7.87696V25.2149C29.8334 26.3192 28.932 27.2126 27.8331 27.2126H5.16703C4.0633 27.2126 3.16675 26.3235 3.16675 25.2149V7.87696C3.16675 6.77266 4.06812 5.87927 5.16703 5.87927H27.8331ZM27.1667 8.54594H5.83341V11.7982L16.5001 18.3167L27.1667 11.7982V8.54594ZM27.1667 14.9233L16.5001 21.4419L5.83341 14.9233V24.5459H27.1667V14.9233Z"
      />
    </svg>
  );
};
