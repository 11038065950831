import clsx from "clsx";
import type React from "react";
import styles from "./ServicesIcon.module.css";

export const ServicesIcon: React.FC<React.ComponentProps<"svg">> = (props) => {
  const { className, ...others } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="24"
      viewBox="0 0 27 24"
      aria-label="Icon of Services"
      aria-hidden="true"
      className={clsx(className, styles.blue90)}
      {...others}
    >
      <g clipPath="url(#clip0_16898_2317)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.83329 3.54594V16.8793H14.8333V19.5459H2.16663C1.05691 19.5459 0.166626 18.6557 0.166626 17.5459V2.87927C0.166626 1.76956 1.05691 0.879272 2.16663 0.879272H21.5C22.6097 0.879272 23.5 1.76956 23.5 2.87927V6.21261H20.8333V3.54594H2.83329Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5 23.5459H4.16663V20.8793H19.5V23.5459Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1667 8.87931V20.8793H24.1667V8.87931H16.1667ZM13.5 8.21265C13.5 7.10808 14.3954 6.21265 15.5 6.21265H24.8333C25.9379 6.21265 26.8333 7.10808 26.8333 8.21265V21.546C26.8333 22.6505 25.9379 23.546 24.8333 23.546H15.5C14.3954 23.546 13.5 22.6505 13.5 21.546V8.21265Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8333 19.5459V16.8793H21.4999V19.5459H18.8333Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_16898_2317">
          <rect
            width="26.6667"
            height="22.6667"
            transform="translate(0.166626 0.879272)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
