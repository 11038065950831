import { CreditCardIcon } from "@/components/icon/CreditCardIcon";
import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import RequiredTag from "@/components/ui/Tag/RequiredTag";
import type { CreditCardBrand } from "@/hooks/usePaymentCards";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputField,
  Link,
  Modal,
  QuestionCircleIcon,
  RadioTile,
  Textarea,
  Typography,
  type UseFormRegisterReturn,
  useModal,
} from "nikkei-ui";
import type React from "react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SelectCreditCard.module.css";
import cardBrandIcons from "./images/CardBrandIcons.png";
import cvcAmex from "./images/CvcAmex.png";
import cvcExceptAmex from "./images/CvcExceptAmex.png";

type CompositionEvent = React.CompositionEvent<HTMLInputElement>;
type CompositionInputType =
  | "expireDate"
  | "cardNumber"
  | "cvc"
  | "creditCardHolderName";
type Props = {
  cards: {
    id: string;
    brand: CreditCardBrand;
    last4Number: string;
    expire: {
      month: string;
      year: string;
    };
  }[];
  registers: {
    cardId: UseFormRegisterReturn<string>;
    newCard: {
      number: UseFormRegisterReturn<string>;
      expire: UseFormRegisterReturn<string>;
      securityCode: UseFormRegisterReturn<string>;
      holderName?: UseFormRegisterReturn<string>;
    };
    applyToOtherServices?: UseFormRegisterReturn<string>;
    agreeConsentPersonalData: UseFormRegisterReturn<string>;
  };
  formErrors: {
    newCard: {
      number?: string;
      expire?: string;
      securityCode?: string;
      holderName?: string;
    };
  };
  onSubmit: () => void;
  onComposition: (event: CompositionEvent, type: CompositionInputType) => void;
  showNewCardForm: boolean;
  currentCardBrand: CreditCardBrand;
  otherServices?: { name: string; planName?: string }[];
  disableButton: boolean;
  errorCardType: "invalid_card" | "script_not_loaded" | undefined;
};

export const SelectCreditCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      props.showNewCardForm &&
      props.disableButton &&
      props.errorCardType === "invalid_card"
    ) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);
  return (
    <Box>
      <Typography as={"h3"} className={"mb-4"} bold>
        <Box className="nid-section-title">
          {t("payments.update.select.title")}
        </Box>
      </Typography>
      <ul className={"mb-4"}>
        <li className={styles.li}>
          <Typography as={"span"} size="14">
            {t("payments.update.select.caution1")}
          </Typography>
        </li>
        <li className={styles.li}>
          <Typography as={"span"} size="14">
            {t("payments.update.select.caution2")}
          </Typography>
        </li>
      </ul>

      <form onSubmit={props.onSubmit}>
        {props.cards.map((card, index) => {
          return (
            <RadioTile
              className={"mb-2"}
              key={card.id}
              id={card.id}
              value={card.id}
              data-testid={`credit-card-select-${index}`}
              label={
                <>
                  <Box className={styles.card}>
                    <Box className={`${styles.brandIconBox} mr-2`}>
                      <CreditCardIcon
                        brand={card.brand}
                        className={styles.brandIcon}
                      />
                    </Box>
                    {`•••• ${card.last4Number}`}
                  </Box>
                  <Typography
                    size="14"
                    color={"gray90"}
                  >{`${card.expire.month}/${card.expire.year}`}</Typography>
                </>
              }
              {...props.registers.cardId}
            />
          );
        })}
        <RadioTile
          label={t("payments.update.select.add_new_card")}
          id={"0"}
          value={"0"}
          defaultChecked={props.showNewCardForm}
          className={"mb-2"}
          data-testid={"credit-card-select-new-card"}
          {...props.registers.cardId}
        />

        {props.showNewCardForm && (
          <NewCreditCardFormWith3DS
            currentCardBrand={props.currentCardBrand}
            number={props.registers.newCard.number}
            expire={props.registers.newCard.expire}
            securityCode={props.registers.newCard.securityCode}
            creditCardHolderName={props.registers.newCard.holderName}
            errors={props.formErrors.newCard}
            onComposition={props.onComposition}
          />
        )}

        {props.otherServices &&
          props.otherServices.length > 0 &&
          props.registers.applyToOtherServices && (
            <ApplyToOtherServicesForm
              otherServices={props.otherServices}
              register={props.registers.applyToOtherServices}
            />
          )}

        {props.showNewCardForm && (
          <AgreeConsentPersonalData
            agreeConsentPersonalData={props.registers.agreeConsentPersonalData}
          />
        )}

        {props.errorCardType === "invalid_card" ? (
          <Box ref={ref}>
            <AlertMessage
              title={t("payments.update.errors.invalid_card.title")}
              testId={"alert-invalid-credit-card"}
              containerClassName="mt-4"
              hasIcon={true}
            >
              {t("payments.update.errors.invalid_card.content")}
            </AlertMessage>
          </Box>
        ) : props.errorCardType === "script_not_loaded" ? (
          <AlertMessage
            title={t("payments.update.errors.timeout.title")}
            testId={"alert-timeout"}
            containerClassName="mt-4"
            hasIcon={true}
          >
            {t("payments.update.errors.timeout.content")}
          </AlertMessage>
        ) : undefined}

        <Button
          size={"full"}
          className={"mt-4"}
          disabled={props.disableButton}
          data-testid="submit"
        >
          {t("payments.update.button.update")}
        </Button>
      </form>
    </Box>
  );
};

type ChangeOtherServiceCardProps = {
  otherServices: { name: string; planName?: string }[];
  register: UseFormRegisterReturn<string>;
};

const ApplyToOtherServicesForm: React.FC<ChangeOtherServiceCardProps> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <Box
      className={`${styles.applyToOtherServicesBox} mt-2 mb-4`}
      data-testid={"apply-to-other-services-form"}
    >
      <Checkbox
        label={
          <Typography bold>
            {t("payments.update.select.apply_other_services")}
          </Typography>
        }
        className="mb-4"
        {...props.register}
      />
      <ol className={"ml-6"}>
        {props.otherServices.map((service) => {
          return (
            <li key={service.name} className={styles.li}>
              {service.name}
              {service.planName && <>（{service.planName}）</>}
            </li>
          );
        })}
      </ol>
    </Box>
  );
};

type AgreeConsentPersonalDataProps = {
  agreeConsentPersonalData: UseFormRegisterReturn<string> | undefined;
};

const AgreeConsentPersonalData: React.FC<AgreeConsentPersonalDataProps> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <Box className="mt-4 mb-6">
      <label htmlFor="consent-personal-data">
        <Typography as="legend" size="16" bold>
          {t("payments.update.add_new_card.consent_personal_data")}
          <RequiredTag />
        </Typography>
      </label>
      <Box className={`${styles.consentContent} mt-4`}>
        <Textarea
          value={t("payments.update.add_new_card.consent_content")}
          readOnly={true}
          className={styles.consent}
        />
        <Box className={`${styles.checkboxWrapper}`}>
          <Checkbox
            data-testid={"agree-consent-personal-data"}
            label={t(
              "payments.update.add_new_card.agree_consent_personal_data",
            )}
            {...props.agreeConsentPersonalData}
          />
        </Box>
      </Box>
    </Box>
  );
};

const UsableCardTypesModal: React.FC = () => {
  const { t } = useTranslation();
  const { registerModal, openModal, closeModal } = useModal();
  return (
    <>
      <Link className={`${styles.iconLink} mb-2`}>
        <QuestionCircleIcon className={`${styles.icon} mr-1`} />
        <Typography as="span" size="14" color={"link"} onClick={openModal}>
          {t("payments.update.add_new_card.usable_card_types")}
        </Typography>
      </Link>
      <Modal
        title={t("payments.update.add_new_card.usable_card_types")}
        testId="usable-card-types-modal"
        {...registerModal}
      >
        <img
          src={cardBrandIcons}
          className={styles.brandIcons}
          alt={t("payments.update.add_new_card.usable_card_types")}
        />

        <Button
          size={"full"}
          data-testid={"close-usable-card-types-modal"}
          className={"mt-6"}
          type={"button"}
          onClick={closeModal}
        >
          {t("payments.update.button.close")}
        </Button>
      </Modal>
    </>
  );
};

const DavidCardUsageModal: React.FC = () => {
  const { t } = useTranslation();
  const { registerModal, openModal, closeModal } = useModal();
  return (
    <>
      <Link className={styles.iconLink}>
        <QuestionCircleIcon className={`${styles.icon} mr-1`} />
        <Typography as="span" size="14" color={"link"} onClick={openModal}>
          {t("payments.update.add_new_card.debit_card")}
        </Typography>
      </Link>
      <Modal
        title={t("payments.update.add_new_card.debit_card")}
        testId="david-card-usage-modal"
        {...registerModal}
      >
        {t("payments.update.add_new_card.debit_card_body")}

        <Button
          size={"full"}
          data-testid={"close-david-card-usage-modal"}
          className={"mt-6"}
          type={"button"}
          onClick={closeModal}
        >
          {t("payments.update.button.close")}
        </Button>
      </Modal>
    </>
  );
};

const SecurityCodeExplainModal: React.FC = () => {
  const { t } = useTranslation();
  const { registerModal, openModal, closeModal } = useModal();

  return (
    <>
      <Link className={`${styles.iconLink}`}>
        <QuestionCircleIcon className={`${styles.icon} mr-1`} />
        <Typography as="span" size="14" color={"link"} onClick={openModal}>
          {t("payments.update.add_new_card.security_code_explain")}
        </Typography>
      </Link>
      <Modal
        title={t("payments.update.add_new_card.security_code_explain")}
        testId="security-code-explain-modal"
        {...registerModal}
      >
        <img
          src={cvcAmex}
          className={`${styles.cvc} mb-2`}
          alt={t("payments.update.add_new_card.security_code_amex_alt")}
        />
        <img
          src={cvcExceptAmex}
          className={styles.cvc}
          alt={t("payments.update.add_new_card.security_code_except_amex_alt")}
        />

        <Button
          size={"full"}
          data-testid={"close-security-code-explain-modal"}
          className={"mt-6"}
          type={"button"}
          onClick={closeModal}
        >
          {t("payments.update.button.close")}
        </Button>
      </Modal>
    </>
  );
};

type NewCreditCardFormPropsWith3DS = {
  currentCardBrand: CreditCardBrand;
  number: UseFormRegisterReturn<string>;
  expire: UseFormRegisterReturn<string>;
  securityCode: UseFormRegisterReturn<string>;
  creditCardHolderName: UseFormRegisterReturn<string> | undefined;
  errors: {
    number?: string;
    expire?: string;
    securityCode?: string;
    holderName?: string;
  };
  onComposition: (event: CompositionEvent, type: CompositionInputType) => void;
};

const NewCreditCardFormWith3DS: React.FC<NewCreditCardFormPropsWith3DS> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <Box className={"mt-6"} data-testid={"new-credit-card-form"}>
      <UsableCardTypesModal />
      <DavidCardUsageModal />

      <fieldset className={"mt-10"}>
        <label htmlFor="input-credit-card-number">
          <Typography
            as="legend"
            size="16"
            bold
            color={props.errors.number ? "red90" : undefined}
            data-testid="legend-credit-card-number"
          >
            {t("payments.update.credit_card.number")}
            <RequiredTag />
          </Typography>
        </label>
        <Box className={"nid-input-control-head"}>
          <InputField
            id={"input-credit-card-number"}
            placeholder={t("payments.update.placeholder.credit_card_number")}
            data-testid={"input-credit-card-number"}
            autoComplete={"cc-number"}
            type={"text"}
            inputMode={"numeric"}
            status={props.errors.number ? "error" : undefined}
            endAdornment={() => (
              <Box className={styles.brandIconBox}>
                <CreditCardIcon
                  className={styles.brandIcon}
                  brand={props.currentCardBrand}
                />
              </Box>
            )}
            onCompositionStart={(e) => props.onComposition(e, "cardNumber")}
            onCompositionEnd={(e) => props.onComposition(e, "cardNumber")}
            {...props.number}
          />
          <FormControl.Validation
            className="nid-error-field"
            status={props.errors.number ? "error" : undefined}
            data-testid="error-credit-card-number"
          >
            {props.errors.number}
          </FormControl.Validation>
        </Box>
      </fieldset>

      <fieldset className={"mt-4"}>
        <label htmlFor="input-credit-card-expire">
          <Typography
            as="legend"
            size="16"
            bold
            color={props.errors.expire ? "red90" : undefined}
          >
            {t("payments.update.credit_card.expire")}
            <RequiredTag />
          </Typography>
        </label>
        <Box className={"nid-input-control-head"}>
          <InputField
            id={"input-credit-card-expire"}
            placeholder={t("payments.update.placeholder.expire")}
            data-testid={"input-credit-card-expire"}
            autoComplete={"cc-exp"}
            className={styles.halfSizeInput}
            type={"text"}
            inputMode={"numeric"}
            onCompositionStart={(e) => props.onComposition(e, "expireDate")}
            onCompositionEnd={(e) => props.onComposition(e, "expireDate")}
            status={props.errors.expire ? "error" : undefined}
            {...props.expire}
          />
          <Typography className={"my-2"} size="12">
            {t("payments.update.add_new_card.expire_helper")}
          </Typography>
          <FormControl.Validation
            className="nid-error-field"
            status={props.errors.expire ? "error" : undefined}
            data-testid="error-credit-card-expire"
          >
            {props.errors.expire}
          </FormControl.Validation>
        </Box>
      </fieldset>

      <fieldset className={"my-4"}>
        <label htmlFor="input-credit-card-security-code">
          <Typography
            as="legend"
            size="16"
            bold
            color={props.errors.securityCode ? "red90" : undefined}
          >
            {t("payments.update.add_new_card.security_code")}
            <RequiredTag />
          </Typography>
        </label>
        <Box className={"nid-input-control-head"}>
          <Box
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <InputField
              id={"input-credit-card-security-code"}
              placeholder={t("payments.update.placeholder.security_code")}
              data-testid={"input-credit-card-security-code"}
              autoComplete={"cc-csc"}
              type={"text"}
              className={styles.halfSizeInput}
              containerClassName={styles.halfSizeInput}
              inputMode={"numeric"}
              status={props.errors.securityCode ? "error" : undefined}
              maxLength={4}
              onCompositionStart={(e) => props.onComposition(e, "cvc")}
              onCompositionEnd={(e) => props.onComposition(e, "cvc")}
              {...props.securityCode}
            />
            <Box
              className="nid-input-container"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SecurityCodeExplainModal />
            </Box>
          </Box>

          <Typography className={"my-2"} size="12">
            {t("payments.update.add_new_card.security_code_helper")}
          </Typography>
          <FormControl.Validation
            className="nid-error-field"
            status={props.errors.securityCode ? "error" : undefined}
            data-testid="error-credit-card-security-code"
          >
            {props.errors.securityCode}
          </FormControl.Validation>
        </Box>
      </fieldset>
      <fieldset className={"my-4"}>
        <label htmlFor="input-credit-card-holder-name">
          <Typography
            as="legend"
            size="16"
            bold
            color={props.errors.holderName ? "red90" : undefined}
          >
            {t("payments.update.add_new_card.credit_card_holder")}
            <RequiredTag />
          </Typography>
        </label>
        <Box className={"nid-input-control-head"}>
          <InputField
            id={"input-credit-card-holder-name"}
            placeholder={t(
              "payments.update.placeholder.credit_card_holder_name",
            )}
            data-testid={"input-credit-card-holder-name"}
            type={"text"}
            inputMode={"text"}
            status={props.errors.holderName ? "error" : undefined}
            maxLength={45}
            onCompositionStart={(e) =>
              props.onComposition(e, "creditCardHolderName")
            }
            onCompositionEnd={(e) =>
              props.onComposition(e, "creditCardHolderName")
            }
            {...props.creditCardHolderName}
          />
          <Typography className={"my-2"} size="12">
            {t("payments.update.add_new_card.card_holder_name_helper")}
          </Typography>
          <FormControl.Validation
            className="nid-error-field"
            status={props.errors.holderName ? "error" : undefined}
            data-testid="error-credit-card-holder-name"
          >
            {props.errors.holderName}
          </FormControl.Validation>
        </Box>
      </fieldset>
    </Box>
  );
};
