import { useSignIn } from "nid-common";
import {
  Box,
  Button,
  InfoCircleFillIcon,
  Link,
  NikkeiIDLogo,
  Typography,
} from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AuthDialog.module.css";

const guideUrl = import.meta.env.VITE_URL_NIKKEI_ID_GUIDE;

export const AuthDialog: React.FC = () => {
  const { t } = useTranslation();
  const { startSignIn } = useSignIn();
  return (
    <Box className={styles.authDialog}>
      <Box className={styles.header}>
        <NikkeiIDLogo width={146} />
        <Typography as="span">
          {t("account_top.text.not_logined_description")}
        </Typography>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.buttonContainer}>
          <Button
            data-testid={"button-login"}
            onClick={() => startSignIn({ replace: false })}
          >
            {t("account_top.button.login")}
          </Button>
          <Button
            variant="border"
            data-testid={"button-signup"}
            onClick={() => startSignIn({ prompt: "create", replace: false })}
          >
            {t("account_top.button.register")}
          </Button>
        </Box>
      </Box>
      <Box className={styles.infoContainer}>
        <InfoCircleFillIcon width={16} height={16} role="img" />
        <Link href={guideUrl} newTab size="14" data-testid="link-nikkei-guide">
          {t("account_top.link.what_is_nikkei_id")}
        </Link>
      </Box>
    </Box>
  );
};
