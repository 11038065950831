import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import { PersonalJobInfoFormProvider } from "@/features/Personal/JobInfo/PersonalJobInfoFormProvider";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

import { Layout } from "../../../components/ui/Layout";

export const PersonalJobInfoPageWrapper = (): React.JSX.Element => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.personal_job_info")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.personal.root} size="14">
            {t("breadcrumbs.personal")}
          </Link>
          <Typography as="span" size="14">
            {t("breadcrumbs.personal_job_info")}
          </Typography>
        </>
      }
      description={t("personal.description")}
    >
      <LoginWallProvider>
        <PersonalJobInfoFormProvider>
          <Outlet />
        </PersonalJobInfoFormProvider>
      </LoginWallProvider>
    </Layout>
  );
};

export default PersonalJobInfoPageWrapper;
