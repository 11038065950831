import type React from "react";

import { useTranslation } from "react-i18next";

import { PaymentsNavigation } from "@/components/ui/Payments/PaymentsNavigation";
import { PaymentsDisplaySwitch } from "@/components/ui/Payments/Top/PaymentsDisplaySwitch";
import { usePaymentCards } from "@/hooks/usePaymentCards";
import { ContentSkeletonLoader } from "nid-common";
import { Box } from "nikkei-ui";
import { PaymentsCardsFeature } from "./PaymentsCardsFeature";
import { PaymentsServicesFeature } from "./PaymentsServicesFeature";
import { getServicesLinkedCard } from "./getServicesLinkedCard";

const NoRegisteredCards: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      as="section"
      className="nid-section nid-section-overflow"
      data-testid="no-registered-card"
    >
      <Box className="nid-section-inner">
        {t("payments.top.text.no_registered_card")}
      </Box>
    </Box>
  );
};

export const PaymentsFeature: React.FC = () => {
  const { cards, status } = usePaymentCards();
  const showTab = getServicesLinkedCard(cards).length > 0;
  return (
    <>
      <PaymentsNavigation />
      {status !== "ok" ? (
        <ContentSkeletonLoader />
      ) : cards.length === 0 ? (
        <NoRegisteredCards />
      ) : (
        <PaymentsDisplaySwitch showTab={showTab}>
          <PaymentsCardsFeature cards={cards} />
          <PaymentsServicesFeature cards={cards} />
        </PaymentsDisplaySwitch>
      )}
    </>
  );
};
