import { Box, Typography } from "nikkei-ui";

import type React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  choices: string[];
  text: string;
};

export const ContractCancelSurveyResult: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Box as="section" className="nid-section nid-section-overflow mb-0">
      <Box className="nid-section-inner-gap8">
        <Typography
          as="h3"
          bold
          lineHeight="135"
          className="nid-typography--vertical-center"
        >
          {t("services.service_contract_cancel.survey.title")}
        </Typography>
        <Box>
          {props.choices.map((choice, index) =>
            choice ? (
              <Typography
                as="p"
                lineHeight="180"
                key={choice}
                data-testid={`cancel-survey-choice-${index}`}
              >
                {choice}
              </Typography>
            ) : null,
          )}
          {props.text && (
            <>
              <Typography as="p" lineHeight="180">
                {t("services.service_contract_cancel.survey.text_title")}
              </Typography>
              <Typography
                as="p"
                lineHeight="180"
                data-testid="cancel-survey-text"
              >
                {props.text}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
